.drawerMenu {
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  &__surah {
    padding: 0.5rem 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #202020;
    direction: rtl;

    &:hover {
      background-color: #f8f9fa;
      border-radius: 0.3rem;
    }

    &--name,
    &--number {
      font-family: "Noto Naskh Arabic", serif;
    }

    &--name {
      margin-right: 2rem;
    }
  }

  &__surahBackground {
    background-color: #f8f9fa;
    border-radius: 0.3rem;
    font-weight: bold;
  }
}
