.videoCard {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media screen and (max-width: 976px) {
    width: 15rem;
    height: 15rem;
  }
  @media screen and (max-width: 880px) {
    width: 17rem;
    height: 17rem;
  }
  @media screen and (max-width: 690px) {
    width: 15rem;
    height: 15rem;
  }
  @media screen and (max-width: 610px) {
    width: 60%;
    height: 100%;
  }
  @media screen and (max-width: 550px) {
    width: 70%;
    height: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 80%;
    height: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
    height: 100%;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    height: 100%;
  }

  // &__coverContainer {
  //   &--cover {
  //   }

  //   &--playIcon {
  //   }
  // }

  &__details {
    // &--channel {
    // }

    &--spec {
      direction: rtl;

      // span {
      // }
    }
  }
}
