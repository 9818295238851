.videoPage {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem 7rem;

  @media screen and (max-width: 1250px) {
    padding: 2rem 0;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 500px) and (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
