.quran {
  width: 100%;
  margin-top: 9vh;
  direction: rtl;

  &--banner {
    width: 100%;
    padding: 7rem 0;
    background-image: url(../../assets/quran/banner.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    padding: 3rem 9rem 1rem;
    min-height: 90vh;

    @media screen and (max-width: 1024px) {
      padding: 3rem 4rem 1rem;
    }
    @media screen and (max-width: 767px) {
      padding: 3rem 2rem 1rem;
    }

    &__search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1rem 0.8rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 2rem;
      width: 50%;
      margin: auto;
      font-size: 1.1em;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        padding: 0.5rem 1rem 0.4rem 0.5rem;
        font-size: 0.8em;
      }
      @media screen and (max-width: 576px) {
        padding: 0.5rem 1rem 0.4rem 0.5rem;
        font-size: 0.7em;
        font-weight: 400;
      }

      &--input {
        flex: 1;
        outline: none;
      }

      &--icon {
        font-size: 2rem !important;
      }
    }

    &__tabs {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #f4f6f7;

      &--tab {
        cursor: pointer;
        color: #272727;
        border-bottom: 3px solid #272727;
        padding-bottom: 0.5rem;
      }
    }

    &__select {
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      cursor: pointer;
      outline: none;
      background-color: transparent;

      option {
        cursor: pointer;
      }
    }

    &__surahs {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1rem;
      column-gap: 1rem;
      padding: 2rem 0;

      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
