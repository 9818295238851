.courseList {
  width: 100%;
  margin-top: 4rem;
  background-color: #f0f1f3;
  padding: 1rem 8rem 0;

  @media screen and (max-width: 920px) {
    padding: 1rem 0;
  }

  // ----------------------- //
  // ---- last course ----- //
  &__lastCourses {
    padding: 2rem 0;
    width: 100%;
    margin-top: 4rem;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0;

      &__btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #0ea5e9;

        &:hover {
          color: #0b73a4;
        }

        a {
          text-decoration: none;
          color: #0ea5e9;

          &:hover {
            color: #0b73a4;
          }
        }
      }

      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.6rem;

        h2 {
          font-family: Peyda-Semibold;
        }

        p {
          color: #353535;
        }
      }
    }
  }

  // ----------------------- //
  // ---- last course ----- //
  &__recentCourses {
    width: 100%;
  }
}

.courseTag {
  padding: 0.5rem 0;

  span {
    margin-left: 0.5rem;
    font-family: Peyda-Semibold;
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: #0da3e81a;
    color: #0ea5e9;
    font-size: clamp(0.4em, 2vw, 0.8em);
  }
}
