.documentaryCard {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  margin: 0.8rem;

  // video cover
  &__image {
    position: relative;
    height: 10rem;

    &:hover {
      img {
        filter: blur(5px);
      }

      .documentaryCard__image__hover {
        opacity: 1;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__hover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: 0.2s ease-out;

      &__icon {
        width: 3em !important;
        height: 3em !important;
      }
    }
  }

  // container
  &__container {
    padding: 0 0.5rem 0.5rem;
    // video title
    &__title {
      padding-top: 0.5rem;
      text-align: right;
      font-weight: 600;
      font-size: clamp(0.7em, 1.2vw, 1em);
    }

    // video source
    &__source {
      font-size: clamp(0.4em, 1vw, 0.8em);
      text-align: right;
      margin: 0.3rem 0;
    }

    // detail
    &__detail {
      display: flex;
      flex-direction: row-reverse;
      gap: 2rem;
      font-size: clamp(0.4em, 1vw, 0.8em);

      // date
      &__date {
        display: flex;
        gap: 0.2rem;
      }

      // view of the video

      &__view {
        display: flex;
        gap: 0.2rem;
      }
    }
  }
}
