.courses {
  width: 100%;
  padding: 0 10%;

  @media screen and (max-width: 920px) {
    padding: 0;
  }
  background-color: #f3f4f6;

  // header
  &__header {
    padding: 8rem 0 4rem;
    text-align: center;
    font-size: clamp(1.5em, 2vw, 2.5em);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    h1 {
      font-family: Peyda-Bold;
    }

    // header icon
    &--icon {
      display: block;
      width: 2.5rem;
      height: 0.7rem;
      margin-bottom: 1rem;
      background-color: #0da3e8;
      border-radius: 0.1rem;
    }
  }

  // filter
  &__filter {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;

    // filter box title
    &__title {
      display: flex;
      gap: 0.6rem;
      font-size: clamp(0.6em, 2vw, 1em);

      // icon
      &--icon {
        font-size: clamp(1em, 2vw, 1.4em);
      }
    }

    // filter box butons
    &__items {
      margin-right: 2rem;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0.2rem;

      @media screen and (max-width: 480px) {
        margin-right: 0;
      }

      &__btn {
        padding: 0.5rem 0.8rem;
        border-radius: 0.5rem;
        cursor: pointer;
        color: #64748b;
        font-size: clamp(0.4em, 2vw, 1em);

        &:hover {
          background-color: #f3f4f6;
          color: black;
        }
      }
    }
  }

  // container
  &__container {
    width: 100%;
    padding-bottom: 10rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 481px) and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
}

.filterActiveTab {
  color: black;
  background-color: #f3f4f6;
}
