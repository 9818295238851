.courseTopic {
  margin-bottom: 1rem;

  // header
  &__header {
    width: 100%;
    padding: 1.5rem 2rem;
    background-color: #f3f4f6;
    border-radius: 1rem;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    // header active
    &--active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #e5e7eb;
    }

    // hover
    &:hover {
      background-color: #e5e7eb;
    }

    // icon
    &--icon {
      font-size: clamp(0.8em, 2vw, 1.6em);
      color: #4f4f4f;
    }

    // title
    &--title {
      font-size: clamp(0.6em, 2vw, 1.2em);
      color: #4f4f4f;
    }
  }

  // main
  &__main {
    width: 100%;
    background-color: #f3f4f6;
    overflow: hidden;
    // transition-property: all;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    // transition-duration: 150ms;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}
