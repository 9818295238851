.profile__main__leftContainer__left {
  &__sectionmark {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &__logo {
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      color: #a8b6ba;
      background-color: #f1f3f4;
      display: grid;
      align-items: center;
      align-content: center;
      justify-content: center;
      @media screen and (max-width: 970px) {
        width: 7rem;
        height: 7rem;
      }

      & > svg {
        width: 4rem;
        height: 4rem;
      }

      &--pic {
        width: 2rem;
      }
    }
  }

  &__create {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 370px) {
      padding: 0.2rem;
    }

    &--text {
      direction: rtl;
      color: #151515;
      font-size: 1em;
      font-family: Peyda;
      margin-bottom: 1rem;
      word-spacing: 0.1rem;
      @media screen and (max-width: 300px) {
        font-size: 0.8em;
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 1rem 2rem 1rem;

      &--button {
        color: #ffffff;
        width: 10rem;
        height: 3rem;
        border-radius: 0.5rem;
        border: none;
        background-color: #81abb9;
        transition: all ease 0.3s;
        &:hover {
          background-color: #6595a4;
          box-shadow: 0 0 0.25em rgba(255, 255, 255, 0.3),
            0 0.25em 1em rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
