.videoPlayHeader {
  width: 100%;
  border-radius: 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  background-color: #172542;
  word-spacing: 0.4rem;
  margin-top: 5rem;
  margin-bottom: 1rem;

  border-radius: 1rem;

  @media screen and (max-width: 568px) {
    padding: 1.5rem;
  }

  h2 {
    font-family: Peyda-Bold;
    font-size: clamp(1.4em, 2vw, 2.4em);
    color: #fff;
  }
}
