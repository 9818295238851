.courseInfoBox {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  // text
  &__text {
    text-align: right;
    line-height: 1.5rem;

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    span {
      font-size: clamp(0.6em, 2vw, 1.2em);
    }

    p {
      font-size: clamp(0.4em, 2vw, 0.7em);
      color: #9f9f9f;
    }
  }

  // icon
  &__icon {
    color: #81abb9;
  }
}
