@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: Peyda;
  src: url(./fonts/Peyda.eot) format("eot"),
    url(./fonts/Peyda.woff) format("woff"), url(./fonts/Peyda.ttf) format("ttf");
}

@font-face {
  font-family: Peyda-Bold;
  src: url(./fonts/Peyda-Bold.eot) format("eot"),
    url(./fonts/Peyda-Bold.woff) format("woff"),
    url(./fonts/Peyda-Bold.ttf) format("ttf");
}

@font-face {
  font-family: Peyda-Semibold;
  src: url(./fonts/Peyda.eot) format("eot"),
    url(./fonts/Peyda.woff) format("woff"), url(./fonts/Peyda.ttf) format("ttf");
}

@font-face {
  font-family: peyda-light;
  src: url(./fonts/peyda-light.eot) format("eot"),
    url(./fonts/peyda-light.woff) format("woff"),
    url(./fonts/peyda-light.ttf) format("ttf");
}

@font-face {
  font-family: ProximaVara;
  src: url(./fonts/ProximaVara.woff2) format("woff");
}
@font-face {
  font-family: surahnames;
  src: url(./fonts/sura_names.woff2) format("woff");
}

* {
  font-family: Peyda;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out; */
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  /* background: #3a4861; */
  border-radius: 0.3rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 0.3rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
}
