.contactUs__main {
  display: flex;
  justify-content: center;
  align-items: center;
  direction: rtl;
  padding: 20rem;
  color: rgb(0, 0, 0);
  text-align: right;
}
@media screen and (max-width: 690px) {
  .contactUs__main {
    padding: 5rem;
  }
}
@media screen and (min-width: 850px) and (max-width: 945px) {
  .contactUs__main {
    padding: 20rem;
  }
}
@media screen and (min-width: 790px) and (max-width: 850px) {
  .contactUs__main {
    padding: 8rem;
  }
}
@media screen and (min-width: 690px) and (max-width: 790px) {
  .contactUs__main {
    padding: 6rem;
  }
}

.contactUs__content {
  height: 17.5rem;
  width: 40rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 0.25rem;
}

.contactUs__section {
  background-color: #eeeeee;
  color: rgb(0, 0, 0);
  padding: 2rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactUs__span {
  display: inline-block;
  font-size: 2em;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  padding: 0.2rem;
}
@media screen and (max-width: 790px) {
  .contactUs__span {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 790px) and (max-width: 850px) {
  .contactUs__span {
    font-size: 1.8rem;
  }
}

a {
  color: rgb(22, 17, 17);
  text-decoration: none;
  background-color: transparent;
}

.style--one--p {
  font-size: 1.5rem;
}
@media screen and (min-width: 850px) and (max-width: 945px) {
  .style--one--p {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 790px) and (max-width: 850px) {
  .style--one--p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 690px) and (max-width: 790px) {
  .style--one--p {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 690px) {
  .style--one--p {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }
}

.style--two--p,
.style--three--p {
  font-size: 1rem;
  color: #8a8d8f;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
@media screen and (min-width: px) {
  .contactUs__span {
  }
}
@media screen and (min-width: 850px) and (max-width: 945px) {
  .style--two--p,
  .style--three--p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 790px) and (max-width: 850px) {
  .style--two--p,
  .style--three--p {
    font-size: 0.86rem;
  }
}

@media screen and (min-width: 690px) and (max-width: 790px) {
  .style--two--p,
  .style--three--p {
    font-size: 0.82rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 690px) {
  .style--two--p,
  .style--three--p {
    font-size: 0.82rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.style--three--p--a {
  display: inline-block;

  color: hsl(0, 0%, 0%);
}

.contactUs__section--divider {
  color: rgb(0, 0, 0);
  height: 210px;
  border-bottom: 1px;
  opacity: 0.2;
  margin-top: 2rem;
  border: none;
  border-right: 1px solid;
}

.contactUs {
  width: 100%;
  display: block;
}

.contactUs__footer {
  background-color: #151e25;
  color: rgb(14, 10, 10);
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  opacity: 1;
  z-index: 1200;
}

.contactUs__footer--container {
  /* position: fixed; */
  background-color: #1e2b35;
  color: white;
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  z-index: 1200;
}

.contactUs__footer--style,
.contactUs__footer--right {
  padding: 1rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
}
@media screen and (max-width: 420px) {
  .contactUs__footer--style {
    padding: 0.5rem 0.1rem;
  }
}

.contactUs__footer--right {
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
}
@media screen and (max-width: 281px) {
  .contactUs__footer--right {
    padding: 0.5rem 0.1rem;
  }
}
@media screen and (min-width: 281px) and (max-width: 385px) {
  .contactUs__footer--right {
    padding: 0.5rem 0.1rem;
  }
}
@media screen and (min-width: 385px) and (max-width: 420px) {
  .contactUs__footer--right {
    padding: 1rem 0.1rem;
  }
}

.contactUs__footer--right--item {
  margin: 0;
  margin-left: 2rem;
  margin-right: 2rem;
}
@media screen and (max-width: 385px) {
  .contactUs__footer--right--item {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
}
@media screen and (min-width: 386px) and (max-width: 800px) {
  .contactUs__footer--right--item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.contactUs__footer--right--item--a {
  color: #fff;
  opacity: 0.7;

  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
.contactUs__footer--right--item--a:hover {
  color: #fff;
  opacity: 1;
}
@media screen and (max-width: 281px) {
  .contactUs__footer--right--item--a {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 330px) {
  .contactUs__footer--right--item--a {
    font-size: 0.55rem;
  }
}
@media screen and (min-width: 330px) and (max-width: 385px) {
  .contactUs__footer--right--item--a {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 385px) and (max-width: 400px) {
  .contactUs__footer--right--item--a {
    font-size: 0.65rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 420px) {
  .contactUs__footer--right--item--a {
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 420px) and (max-width: 460px) {
  .contactUs__footer--right--item--a {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 460px) and (max-width: 500px) {
  .contactUs__footer--right--item--a {
    font-size: 0.85rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 550px) {
  .contactUs__footer--right--item--a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 550px) and (max-width: 600px) {
  .contactUs__footer--right--item--a {
    font-size: 1rem;
  }
}

.contactUs__footer--divider {
  /* padding-top: 1.5rem; */
  width: 90%;
  color: hsl(0, 0%, 100%);
  padding: 0 auto;
  padding-bottom: 1rem;
  border-bottom: 1px solid;
  opacity: 0.2;
}

.contactUs__footer--left {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.contactUs__footer--left--p {
  font-size: 1rem;
  margin-top: revert;
  margin-bottom: 0;
}
@media screen and (max-width: 281px) {
  .contactUs__footer--left--p {
    font-size: 0.45rem;
  }
}
@media screen and (min-width: 281px) and (max-width: 320px) {
  .contactUs__footer--left--p {
    font-size: 0.48rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 350px) {
  .contactUs__footer--left--p {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 350px) and (max-width: 375px) {
  .contactUs__footer--left--p {
    font-size: 0.55rem;
  }
}
@media screen and (min-width: 375px) and (max-width: 395px) {
  .contactUs__footer--left--p {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 395px) and (max-width: 420px) {
  .contactUs__footer--left--p {
    font-size: 0.63rem;
  }
}
@media screen and (min-width: 420px) and (max-width: 490px) {
  .contactUs__footer--left--p {
    font-size: 0.65rem;
  }
}
@media screen and (min-width: 490px) and (max-width: 522px) {
  .contactUs__footer--left--p {
    font-size: 0.76rem;
  }
}
@media screen and (min-width: 522px) and (max-width: 600px) {
  .contactUs__footer--left--p {
    font-size: 0.85rem;
  }
}

.contactUs__footer--left--p--designer {
  color: gray;
  margin: 0.4rem;

  font-size: 0.8rem;
}
