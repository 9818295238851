.madahContent {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #172542;
  direction: rtl;

  // header
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-family: Peyda-Bold;
      color: #ffffff;
      font-size: clamp(1em, 2vw, 1.6em);
      padding-right: 1rem;
      border-right: 0.5rem solid #ffffff;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    p {
      color: #ffffffb1;
      font-size: clamp(0.6em, 2vw, 1em);
    }
  }
}
