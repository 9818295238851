.card {
  @media screen and (max-width: 445px) {
    width: 23rem;
  }
  @media screen and (max-width: 430px) {
    width: 22rem;
  }
  @media screen and (max-width: 415px) {
    width: 21rem;
  }
  @media screen and (max-width: 400px) {
    width: 20rem;
  }
  @media screen and (max-width: 385px) {
    width: 19rem;
  }
  @media screen and (max-width: 370px) {
    width: 18rem;
  }
  @media screen and (max-width: 355px) {
    width: 17rem;
  }
  @media screen and (max-width: 340px) {
    width: 16rem;
  }
  @media screen and (max-width: 325px) {
    width: 15rem;
  }
  @media screen and (max-width: 310px) {
    width: 14rem;
  }

  // &--image {
  // }

  &__text {
    &--title {
      @media screen and (max-width: 450px) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 420px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 390px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 360px) {
        font-size: 1.1rem;
      }
      @media screen and (max-width: 330px) {
        font-size: 1rem;
      }
    }

    &--desc {
      font-family: Peyda;
      color: #575866;
      @media screen and (max-width: 450px) {
        font-size: 0.95rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 0.9rem;
      }
      @media screen and (max-width: 350px) {
        font-size: 0.85rem;
      }
      @media screen and (max-width: 300px) {
        font-size: 0.8rem;
      }
    }

    &--link {
      direction: rtl;
      transition: all 0.3s;

      &:hover {
        padding: 0.5rem 0.5rem;
        background-image: linear-gradient(to top left, #044e42, #117b62);
        color: #fff;
        border-radius: 0.5rem;
        letter-spacing: 1px;
      }
    }
  }
}
