.article {
  margin-top: 8rem;
  padding: 0 10%;

  // main
  &__main {
    width: 100%;
    background-color: #81abb950;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 1rem;

    // image
    &__img {
      width: 30%;
      float: left;
      margin: 0 1.5rem 1.5rem 0;

      @media screen and (min-width: 769px) and (max-width: 1200px) {
        margin: 0 1.5rem 0.5rem 0;
      }

      @media screen and (max-width: 768px) {
        margin-right: 1rem;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        float: none;
      }

      img {
        border-radius: 0.5rem;
      }
    }

    // text
    &__text {
      width: 100%;
      line-height: 3rem;
      word-spacing: 0.15rem;
      text-align: justify;
      direction: rtl;
      font-size: clamp(0.6em, 2vw, 1em);

      @media screen and (min-width: 481px) and (max-width: 768px) {
        line-height: 2rem;
      }

      @media screen and (max-width: 480px) {
        line-height: 1.5rem;
      }
    }
  }
}
