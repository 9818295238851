.topicSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f3f4f6;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  color: #565656;
  transition: all ease 0.15s;

  // hover
  &:hover {
    color: #2ed773;

    a {
    }

    .topicSection__main--id {
      color: #fff;
      background-color: #2ed773;
    }
  }

  // main
  &__main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    font-size: clamp(0.6em, 2vw, 1.2em);

    // id
    &--id {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 0.2rem 0.7rem 0 0.5rem;
      transition: all ease 0.15s;
    }
  }

  // detail
  &__detail {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // status
    &--status {
      font-size: clamp(0.4em, 2vw, 0.8em);
      padding: 0.3rem 0.5rem;
      background-color: #e5e7eb;
      word-spacing: 0.2em;
      border-radius: 0.3rem;
    }

    // duration
    &__duration {
      display: flex;
      align-items: center;
      gap: 0.3rem;

      // icon
      &--icon {
        font-size: clamp(0.6em, 2vw, 1.2em);
      }

      // time
      &--time {
        padding-top: 0.2rem;
        color: #7a7a7a;
      }
    }
  }
}
