.commentBox{
    margin-top: 2rem;
    width: 100%;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;

    // header
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        // title
        &--title{
            font-size: clamp(1em,2vw,1.8em);
            font-family: Peyda-Bold;
            color: #1d1d35;
            padding-right: 1rem;
            border-right: 0.5rem solid #0da4e4;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        // newComment button
        &--newComment{
            background-color: #0da4e4;
            color: #fff;
            padding: 0.5rem 0.8rem;
            border-radius: 1rem;
            transition: all ease-in 0.13s;

            // hover
            &:hover{
               transform: translate(0 , -0.2rem);
            }

        }
    }

    // container
    &__container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}