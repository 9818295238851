.iframe {
  height: 100vh;
  width: 100%;
  margin-top: 1.3rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Peyda;
    color: #3d475e;
  }

  hr {
    margin: 0 1rem;
  }

  &__info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 1rem;

    &__right {
      text-align: right;
    }
  }

  &__description {
    color: red;
    display: flex;
    cursor: pointer;
    margin-right: auto;
    direction: rtl;
    margin-top: 1rem;
    &:hover {
      text-decoration: underline;
    }
    p {
      color: red;
    }
  }
}
