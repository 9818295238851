// podcastEpisode.scss

.home__player {
  margin-top: 2rem;
  margin-bottom: 20rem;

  &__title {
    margin-bottom: 20rem;
  }

  &__player {
    margin-top: 12rem;
  }
}
