.courseContent {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;

  // header
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-family: Peyda-Bold;
      color: #1d1d35;
      font-size: clamp(1em, 2vw, 1.6em);
      padding-right: 1rem;
      border-right: 0.5rem solid #81abb9;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    p {
      color: #7a7a7a;
      font-size: clamp(0.6em, 2vw, 1em);
    }
  }
}
