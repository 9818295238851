.navbar {
  direction: rtl;
  width: 100%;
  height: 3.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 20;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    height: 3.5rem;
  }
  @media screen and (max-width: 920px) {
    padding: 0.5rem 1rem;
    justify-content: space-between;
  }

  &__btn {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    transition: all 0.5s;

    &--register {
      background-color: #81abb9;
      color: #fff;
      &:hover {
        background-color: #7eb8cb;
      }
    }

    &--login {
      &:hover {
        color: #81abb9;
      }
    }
  }

  &__right {
    &--logo {
      @media screen and (max-width: 920px) {
        display: none;
      }
    }

    &__dropdown {
      @media (max-width: 920px) {
        display: inline-block;
        padding: 0 1rem;
      }
      @media (max-width: 350px) {
        padding: 0 0.3rem;
      }

      &--guide {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid green;
        margin: 0 auto;
      }

      &__links {
        background-color: #fff;
        color: #1d1d35;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        text-align: right;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 8px -5px;
        direction: rtl;
        animation: growDown 0.5s ease-in-out forwards;

        @media (min-width: 921px) {
          display: none;
        }
        @media (max-width: 920px) {
          top: 3.5rem;
        }
        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
          font-size: 0.8em;
          font-weight: bold;
        }

        &--link {
          @media (max-width: 576px) {
            width: 100%;
          }

          &:hover {
            background-color: #f5f9fc;
            border-radius: 0.3rem;
          }
        }
      }
      &--hidden {
        display: none;
        animation: growUp 1s ease;
      }
    }
  }

  &__links {
    @media (max-width: 920px) {
      display: none;
    }

    &--link {
      outline: none;
      font-family: Peyda;
      color: #1d1d35;
    }
  }

  &__icons {
    color: #1d1d35;

    &__account {
      cursor: pointer;
      position: relative;
      &__modalContainer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        transition: all 0.5s;

        &--hide {
          visibility: hidden;
          top: -1%;
        }

        &__modal {
          display: flex;
          flex-direction: column;
          background-color: #f5f9fc;
          position: absolute;
          top: 7.5%;
          left: 3%;
          padding: 1rem;
          border-radius: 0.5rem;
          width: 18rem;
          transition: all 0.5s;

          &--triangle {
            width: 0;
            height: 0;
            position: absolute;
            top: -17%;
            left: 0;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid #f5f9fc;
            margin: 2rem;
          }

          &__item {
            display: flex;
            align-items: center;
            padding: 0.8rem;
            border-radius: 0.5rem;

            &:hover {
              background-color: #81abb9;
              color: #fff;

              p {
                color: #fff;
              }
            }

            p {
              font-size: 0.9em;
            }

            &--icon {
              font-size: 1.5rem;
              margin-left: 0.3rem;
            }
          }
        }

        &__modalHide {
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;
        }

        p {
          font-family: Peyda;
          color: #1d1d35;

          @media (max-width: 750px) {
            padding: 0 0.5rem;
            font-size: 0.8em;
          }
          @media (max-width: 376px) {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes growDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes growUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}
