.commentCard {
  width: 100%;
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;

  // right col
  &__rightCol {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    // user logo
    &__userLogo {
      // image
      &--img {
        margin: 0 auto;
        width: 80%;
        border-radius: 50%;
      }
    }

    // user flag
    &--userFlag {
      padding: 0.2rem 0.6rem;
      background-color: #1d1d35;
      font-size: clamp(0.4em, 2vw, 0.8em);
      color: #fff;
      border-radius: 0.3rem;
    }
  }

  // left col
  &__leftCol {
    width: 90%;
    padding-left: 0.5rem;

    // head
    &__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      // detail
      &__detail {
        display: flex;
        flex-direction: column;

        // user Name
        &--userName {
          font-size: clamp(0.8em, 2vw, 1.6em);
          color: #3f3f46;
        }

        // comment date modified
        &--date {
          font-size: clamp(0.4em, 2vw, 0.8em);
          color: #9f9f9f;
        }
      }

      &--btn {
        height: fit-content;
        font-size: clamp(0.8em, 2vw, 1.6em);
        color: #3f3f46;
        padding-left: 0.5rem;
        cursor: pointer;

        // hover
        &:hover {
          color: #1d1d35;
        }
      }
    }

    // text
    &__text {
      text-align: right;
      line-height: 2rem;

      p {
        font-size: clamp(0.6em, 2vw, 1.2em);
        font-family: Peyda-Light;
        color: #3f3f46;
      }
    }
  }
}
