.post-single {
  background-color: #ffffff !important;
  padding: 20px;
  border-radius: 20px !important;
  margin-bottom: 30px;
  margin-top: 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.madah {
  padding: 10rem;
  padding-top: 2rem;
  background-color: #23304b;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  &__header {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
      padding: 0 10%;
    }

    &__cover {
      @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
        height: 20rem;
        background-position: center center;
        padding: 0;
      }

      @media screen and (max-width: 768px) {
        padding: 1rem;
      }

      img {
        margin-bottom: 1rem;
        border-radius: 2rem;
        text-align: center;
        max-width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 5rem !important;
        object-fit: cover;
      }
    }

    &__detail {
      text-align: right;
      flex-direction: column;
      justify-content: space-between;

      &__text {
        @media screen and (max-width: 1000px) {
          margin-bottom: 2rem;
        }

        &__title {
          font-family: Peyda-Bold;
          font-size: clamp(1.6em, 1.6vw, 2.4em);
          margin-bottom: 1rem;
          color: #ffffff;
        }

        &__description {
          font-family: Peyda-light;
          font-size: clamp(0.8em, 2vw, 1.5em);
          color: #ffffffb1;
        }
      }

      &__signUp {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--btn {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-family: Peyda-Bold;
          font-size: clamp(0.6em, 2vw, 1.4em);
          background-color: #1d1d35;
          color: #fff;
          padding: 1rem;
          border-radius: 0.7rem;
          cursor: pointer;
          transition: all ease-in 0.2s;

          &:hover {
            box-shadow: 0 0 3px black;
            transform: translate(0, -5px);
          }
        }

        &__price {
          font-family: Peyda-Bold;
          font-size: clamp(1.6em, 2vw, 3em);
          color: #1d1d35;
        }
      }
    }
  }

  &__container {
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
    }

    &__main {
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0 10%;
      }

      &__info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
