.footer {
  &__container {
    // @media screen and (max-width: 450px) {
    //   padding: 2rem 1rem;
    // }
    // @media screen and (max-width: 390px) {
    //   padding: 2rem 0.5rem;
    // }

    @media screen and (max-width: 865px) {
      padding: 2rem 1rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 2rem 5rem;
    }
    @media screen and (max-width: 768px) {
      padding: 2rem 2rem;
    }

    &__section {
      // padding: 1rem;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
      }
      @media screen and (max-width: 420px) {
        flex-wrap: wrap;
        row-gap: 2rem;
      }
    }

    &__links {
      // padding: 0 1rem;
      // font-size: 1.3rem;
      @media screen and (max-width: 1200px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 920px) {
        font-size: 1.1rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        padding: 0;
      }
    }

    &__logo {
      @media screen and (max-width: 768px) {
        width: 30%;
        aspect-ratio: 3/2;
        object-fit: contain;
        mix-blend-mode: color-burn;
      }

      &__copyrights {
        @media screen and (max-width: 768px) {
          padding: 2rem 0 3rem;

          &--text {
            display: none;
          }
        }
        &--text {
          color: darkgray;
          direction: rtl;
          text-align: center;
          @media screen and (max-width: 865px) {
            font-size: 0.9em;
          }
        }

        &--title {
          // background: linear-gradient(to top left, #149e72, #43b156);
          background: linear-gradient(to right, #149e72, #43b156);
          // background: -webkit-linear-gradient(#17f3ad, #43b156);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @media screen and (max-width: 865px) {
            font-size: 2rem;
          }
        }

        &__second {
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
            padding: 4rem 0 2rem;

            &--text {
              text-align: center;
            }
          }
        }
      }

      // &__info {
      //   &--logo {
      //   }
      // }
    }
  }
}
