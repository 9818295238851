.commentForm {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;

  // head
  &__head {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 1rem;

    // user image
    &--img {
      display: flex;
      flex-direction: row-reverse;

      img {
        width: 70%;
        border-radius: 50%;
      }
    }

    // detail
    &__detail {
      text-align: right;
      margin-right: -1rem;

      // user name
      &--userName {
        font-size: clamp(1em, 2vw, 1.6em);
        color: #3f3f46;
      }

      // reply to
      &--replyTo {
        display: flex;
        flex-direction: row-reverse;
        gap: 0.5rem;
        font-size: clamp(0.5em, 2vw, 1em);
        color: #3f3f46;
        font-family: Peyda-Light;
      }
    }
  }

  // comment
  &__comment {
    &--input {
      width: 100%;
      border-radius: 1rem;
      padding: 1rem;
      background-color: #f3f4f6;
      text-align: right;
      color: #3f3f46;
      font-size: clamp(0.6em, 2vw, 1.2em);
      line-height: 2rem;
      font-family: Peyda-Light;
      word-spacing: 0.3em;

      &:focus-visible {
        outline: 0;
      }
    }
  }

  //btns
  &__btns {
    display: flex;
    align-items: center;
    gap: 1rem;

    // submit and cancel button
    &--submit,
    &--cancel {
      font-size: clamp(0.6em, 2vw, 1.2em);
      padding: 0.3rem 1rem;
      border-radius: 0.5rem;
      opacity: 0.9;

      // hover
      &:hover {
        opacity: 1;
      }
    }

    // submit
    &--submit {
      background-color: #172542;
      color: #fff;
    }

    // cancel
    &--cancel {
      background-color: #f3f4f6;
    }
  }
}
