.doubtsCard {
  width: 100%;
  background-color: #fff;
  border-radius: 1rem;

  // course card image
  &__image {
    img {
      border-radius: 1rem;
      width: 100%;
      height: 12rem;
      object-fit: cover;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        height: 9rem;
      }
    }
  }

  // course card box
  &__box {
    padding: 0 1rem;

    &__tags {
      display: flex;
      justify-content: flex-end;
    }

    &__title {
      text-align: right;
      margin-bottom: 0.8rem;
      word-spacing: 2px;

      span {
        font-family: Peyda-Semibold;
        font-size: clamp(0.7em, 2vw, 1em);
      }
    }

    &__description {
      text-align: right;

      p {
        font-family: Peyda-light;
        font-size: clamp(0.6em, 2vw, 0.9em);
      }
    }
  }

  // course footer
  &__footer {
    margin-top: 1rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;

    // top of footer
    &__top {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #f0f0f0;

      span {
        color: #666;
        font-size: clamp(0.4em, 2vw, 0.8em);
      }

      // author name
      &__author {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &--icon {
          margin-bottom: 0.25rem;
        }
      }

      // course student count
      &__view {
        display: flex;
        gap: 0.3rem;
      }

      // course duration
      &__time {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &--icon {
          margin-bottom: 0.25rem;
          color: #666;
        }
      }

      // course rating
      &__rating {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        span {
          padding: 0.25rem 0;
          color: #fbbf24;
        }

        &--icon {
          margin-bottom: 0.25rem;
          color: #fbbf24;
          fill: #fbbf24;
        }
      }
    }
  }
}

.articleTag {
  padding: 0.5rem 0;

  span {
    margin-left: 0.5rem;
    font-family: Peyda-Semibold;
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: #0da3e81a;
    color: #0ea5e9;
    font-size: clamp(0.4em, 2vw, 0.8em);
  }
}
