.courseDescription {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  // title
  &--title {
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 3rem;

    span {
      color: #1d1d35;
      font-family: Peyda-Bold;
      font-size: clamp(1.6em, 2vw, 2.6em);
      padding-right: 1rem;
      border-right: 0.7rem solid #2ed573;
    }
  }

  // detail
  &__detail {
    position: relative;
    // content
    &--content {
      max-height: 200px;
      overflow: hidden;

      p {
        font-family: Peyda-Light;
        font-size: clamp(0.6em, 2vw, 1.2em);
        color: #3f3f46;
        text-align: right;
        line-height: 2rem;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: clamp(1em, 2vw, 1.6em);
        text-align: right;
        margin: 2rem 0;
        color: #1d1d35;
      }
    }

    // box shadow
    &--shadow {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(180deg, #00000000 40%, #ffffff 90%);
    }
  }

  // show more btn
  &__showMore {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    &--btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: #1d1d35;
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 1.5rem;
      font-size: clamp(0.6em, 2vw, 1.2em);
      word-spacing: 0.2rem;
      transition: all ease-in-out 0.25s;

      &:hover {
        transform: translate(0, -0.5rem);
      }
    }
  }
}
