.audioPlayer {
  width: 100%;

  // background: rgb(198, 233, 245);
  // background: linear-gradient(
  //   0deg,
  //   rgba(198, 233, 245, 1) 0%,
  //   rgba(255, 255, 255, 1) 40%,
  //   rgba(255, 255, 255, 1) 72%,
  //   rgba(198, 233, 245, 1) 100%
  // );

  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;
  padding: 1.5rem;
  // cover
  &--cover {
    width: 15%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  // main
  &__main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 85%;

    //   title
    &--title {
      font-family: Peyda-Bold;
      text-align: right;
      font-size: clamp(0.8em, 2vw, 1.6em);
    }

    // controls
    &__controls {
      padding: 0 1rem;
      width: 100%;
      height: 100%;
      display: flex;
      gap: 1rem;
      display: flex;
      align-items: center;

      //   button
      &--btn {
        font-size: clamp(3em, 2vw, 4em);
        margin-bottom: 0.25rem;

        &:hover {
          color: #81abb9;
          cursor: pointer;
        }

        &:active {
          transform: translate(0, -4px);
        }
      }

      //   time
      &--time {
        font-size: clamp(0.6em, 2vw, 1.2em);
      }

      //   timeRail
      &--timeRail {
        font-size: clamp(2em, 2vw, 3em);
        height: 0.5rem !important;
        margin-bottom: 0.25rem;
        transition: all 0.1s;
      }

      //   volumeRail
      &--volumeRail {
        height: 0.5rem !important;
        width: 100px !important;
        font-size: clamp(2em, 2vw, 3em);
        margin-bottom: 0.25rem;
      }
    }
  }
}
