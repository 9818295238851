.home {
  color: #1d1d35;
  @media screen and (max-width: 768px) {
    margin-top: 3.5rem;
  }

  &__live {
    @media screen and (max-width: 576px) {
      padding: 0 0.5rem;
    }
  }

  &--title {
    font-weight: bold;
    @media screen and (max-width: 450px) {
      font-size: 1.5rem;
    }
  }

  &__channelCategory {
    direction: rtl;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 576px) {
      border-radius: 0.5rem;
      margin: 0.5rem;
    }

    &__glider {
      position: absolute;
      background-color: #81abb9;
      width: 33.3%;
      border-radius: 1rem;
      height: 100%;
      transition: all 0.4s ease-in-out;
      @media screen and (max-width: 576px) {
        border-radius: 0.5rem;
      }
    }

    &__item {
      flex: 1;
      text-align: center;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      z-index: 2;
      @media screen and (max-width: 576px) {
        padding: 0.5rem 0;
      }
    }

    &--name {
      font-size: 1.1em;
    }
  }

  &__tvs {
    @media screen and (max-width: 576px) {
      padding: 0 0.5rem;
    }
  }

  &__cinema {
    height: 100vh;
    margin: 0 2rem;
    border-radius: 1rem;
    background-image: url("../../assets/islamicMovies.jpg");
    background-position: 70% 70%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 576px) {
      margin: 0 0.5rem;
    }

    &__shadow {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0px 300px 200px 100px rgb(255, 255, 255) inset;
      -webkit-box-shadow: 0px 300px 200px 100px rgb(255, 255, 255) inset;
      -moz-box-shadow: 0px 300px 200px 100px rgb(255, 255, 255) inset;
    }

    &__desc {
      flex: 1;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 2rem;

      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;

        &--heading {
          font-family: Peyda-Bold;
          font-size: clamp(1.5em, 4vw, 3em);
          margin: 0;
          padding: 1rem 0 0 0;
        }

        &--icon {
          font-size: clamp(2em, 10vw, 5em);
        }
      }

      &--text {
        font-size: clamp(0.7em, 2vw, 1em);
        padding: 0 2rem;
      }
    }

    &__cards {
      height: 30%;
      padding: 1rem;
      overflow: hidden;
      display: flex;
      z-index: 10;
      column-gap: 2rem;

      @media screen and (max-width: 768px) {
        column-gap: 1rem;
      }

      &__list {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2rem;

        @media screen and (max-width: 768px) {
          column-gap: 1rem;
        }
        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 430px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .card1 {
          @media screen and (max-width: 576px) {
            display: none;
          }
        }
        .card2 {
          @media screen and (max-width: 430px) {
            display: none;
          }
        }
        &__card {
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
          background-position: center;
          background-size: cover;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.05);
            transition: all 0.3s;
          }
        }
      }

      &__btn {
        cursor: pointer;
        background-color: #1d1d35;
        border-radius: 50%;
        height: fit-content;
        color: #fff;
        padding: 1rem;
        align-self: center;

        @media screen and (max-width: 768px) {
          padding: 0.5rem;
        }
        &--icon {
          font-size: clamp(1em, 3vw, 2em);
        }
      }
    }
  }

  &__quranSection {
    @media screen and (max-width: 576px) {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
    }

    &__info {
      @media screen and (max-width: 1024px) {
        padding: 0 0.5rem;
      }
      @media screen and (max-width: 576px) {
        align-items: center;
        justify-content: flex-start;
      }

      &--button {
        transition: all 0.1s ease-in-out;
        outline: none;
        border: none;
        @media screen and (max-width: 1024px) {
          font-size: 1em;
        }
        @media screen and (max-width: 768px) {
          margin-top: 1rem;
        }

        &:hover {
          box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
          transform: translateY(-0.2rem);
          transition: all 0.1s ease-in-out;
        }
      }

      &--title {
        @media screen and (max-width: 1024px) {
          font-size: 1.6em;
        }
        @media screen and (max-width: 768px) {
          padding-left: 3rem;
        }
        @media screen and (max-width: 576px) {
          margin-top: 0;
          padding-top: 0;
          padding-left: 0;
        }
      }

      &--subtitle {
        width: 80%;
        direction: rtl;
        text-align: justify;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
        @media screen and (max-width: 576px) {
          padding: 0.5rem 6rem;
        }
        @media screen and (max-width: 450px) {
          padding: 0;
          font-size: 0.8em;
        }
      }
    }

    &__iconContainer {
      @media screen and (max-width: 1024px) {
        padding: 0 0.5rem;
      }

      &--icon {
        @media screen and (max-width: 1024px) {
          width: 27rem;
          height: 22rem;
        }
        @media screen and (max-width: 768px) {
          width: 17rem;
          height: 15rem;
        }
      }
    }
  }

  &__topSection {
    background-image: url(../../assets/quran/hadithBanner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 0;

    @media screen and (max-width: 665px) {
      height: 50%;
    }
    @media screen and (max-width: 576px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    @media screen and (max-height: 400px) {
      height: 90%;
    }
    @media screen and (max-width: 365px) {
      flex-direction: column;
    }

    &__info {
      width: 60%;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        padding-left: 1rem;
        width: 55%;
      }
      @media screen and (max-width: 450px) {
        width: 50%;
      }

      &--title {
        direction: rtl;
        font-size: 1.9em;
        @media screen and (max-width: 1024px) {
          font-size: 1.6em;
          padding: 0;
        }
        @media screen and (max-width: 768px) {
          font-size: 1.3em;
        }
        @media screen and (max-width: 576px) {
          padding: 0;
          font-size: 1.2em;
        }
        @media screen and (max-width: 450px) {
          font-size: 1em;
          margin: 0.3rem 0;
        }

        span {
          @media screen and (max-width: 450px) {
            font-size: 0.7em;
          }
        }
      }

      &--subtitle {
        direction: rtl;
        @media screen and (max-width: 665px) {
          display: none;
        }
        @media screen and (max-width: 410px) {
          width: 90%;
        }
        @media screen and (max-width: 370px) {
          width: 80%;
        }
      }
    }
  }

  // &__character {
  // }

  &__player {
    @media screen and (max-width: 920px) {
      padding: 2rem 2rem 4rem;
    }
    @media screen and (max-width: 885px) {
      padding: 2rem 0rem 4rem;
    }
  }

  &__courses {
    &__cards {
      direction: rtl;
    }

    &__link {
      transition: all 0.3s ease-in-out;
      border: 1px solid #494949;

      &:hover {
        background-image: linear-gradient(to top left, #044e42, #117b62);
        color: #fff;
        border-color: transparent;
      }
    }
  }

  &__podcasts {
    padding: 2rem 2rem 4rem;

    &__container {
      padding: 1rem 1rem;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 2rem;
      row-gap: 2rem;
      direction: rtl;
      grid-column-end: revert;
      @media screen and (max-width: 790px) {
        grid-template-columns: auto;
      }
      @media screen and (max-width: 470px) {
        grid-template-columns: auto;
      }
      @media screen and (max-width: 445px) {
        justify-items: center;
      }
    }
  }

  &__videos {
    padding: 2rem 2rem 4rem;

    &__container {
      padding: 1rem 1rem;
      display: flex;
      flex-wrap: wrap;
      direction: rtl;
      justify-content: flex-start;
      row-gap: 2rem;
      column-gap: 2rem;
      @media screen and (max-width: 1550px) {
        padding: 1rem 4.5rem;
        column-gap: 4.5rem;
      }
      @media screen and (max-width: 1520px) {
        padding: 1rem 4.5rem;
        column-gap: 4rem;
      }
      @media screen and (max-width: 1496px) {
        padding: 1rem 3.9rem;
        column-gap: 4.4rem;
      }
      @media screen and (max-width: 1488px) {
        padding: 1rem 3.8rem;
        column-gap: 4.3rem;
      }
      @media screen and (max-width: 1480px) {
        padding: 1rem 3.7rem;
        column-gap: 4.2rem;
      }
      @media screen and (max-width: 1472px) {
        padding: 1rem 3.6rem;
        column-gap: 4.1rem;
      }
      @media screen and (max-width: 1464px) {
        padding: 1rem 3.5rem;
        column-gap: 4rem;
      }
      @media screen and (max-width: 1456px) {
        padding: 1rem 3.4rem;
        column-gap: 3.9rem;
      }
      @media screen and (max-width: 1448px) {
        padding: 1rem 3.3rem;
        column-gap: 3.8rem;
      }
      @media screen and (max-width: 1440px) {
        padding: 1rem 3.2rem;
        column-gap: 3.7rem;
      }
      @media screen and (max-width: 1432px) {
        padding: 1rem 3.1rem;
        column-gap: 3.6rem;
      }
      @media screen and (max-width: 1424px) {
        padding: 1rem 3rem;
        column-gap: 3.5rem;
      }
      @media screen and (max-width: 1416px) {
        padding: 1rem 2.9rem;
        column-gap: 3.4rem;
      }
      @media screen and (max-width: 1408px) {
        padding: 1rem 2.8rem;
        column-gap: 3.3rem;
      }
      @media screen and (max-width: 1400px) {
        padding: 1rem 2.7rem;
        column-gap: 3.2rem;
      }
      @media screen and (max-width: 1392px) {
        padding: 1rem 2.6rem;
        column-gap: 3.1rem;
      }
      @media screen and (max-width: 1384px) {
        padding: 1rem 2.5rem;
        column-gap: 3rem;
      }
      @media screen and (max-width: 1376px) {
        padding: 1rem 2.4rem;
        column-gap: 2.9rem;
      }
      @media screen and (max-width: 1368px) {
        padding: 1rem 2.3rem;
        column-gap: 2.8rem;
      }
      @media screen and (max-width: 1360px) {
        padding: 1rem 2.2rem;
        column-gap: 2.7rem;
      }
      @media screen and (max-width: 1352px) {
        padding: 1rem 2.1rem;
        column-gap: 2.6rem;
      }
      @media screen and (max-width: 1344px) {
        padding: 1rem 2rem;
        column-gap: 2.5rem;
      }
      @media screen and (max-width: 1336px) {
        padding: 1rem 1.9rem;
        column-gap: 2.4rem;
      }
      @media screen and (max-width: 1328px) {
        padding: 1rem 1.8rem;
        column-gap: 2.3rem;
      }
      @media screen and (max-width: 1320px) {
        padding: 1rem 1.7rem;
        column-gap: 2.2rem;
      }
      @media screen and (max-width: 1312px) {
        padding: 1rem 1.6rem;
        column-gap: 2.1rem;
      }
      @media screen and (max-width: 1304px) {
        padding: 1rem 1.5rem;
        column-gap: 2rem;
      }
      @media screen and (max-width: 1296px) {
        padding: 1rem 1.4rem;
        column-gap: 1.9rem;
      }
      @media screen and (max-width: 1288px) {
        padding: 1rem 1.3rem;
        column-gap: 1.8rem;
      }
      @media screen and (max-width: 1280px) {
        padding: 1rem 1.2rem;
        column-gap: 1.7rem;
      }
      @media screen and (max-width: 1272px) {
        padding: 1rem 1.1rem;
        column-gap: 1.6rem;
      }
      @media screen and (max-width: 1256px) {
        padding: 1rem 5.5rem;
        column-gap: 6rem;
      }
      @media screen and (max-width: 1248px) {
        padding: 1rem 5.4rem;
        column-gap: 5.9rem;
      }
      @media screen and (max-width: 1241px) {
        padding: 1rem 5.3rem;
        column-gap: 5.8rem;
      }
      @media screen and (max-width: 1235px) {
        padding: 1rem 5.2rem;
        column-gap: 5.7rem;
      }
      @media screen and (max-width: 1229px) {
        padding: 1rem 5.1rem;
        column-gap: 5.6rem;
      }
      @media screen and (max-width: 1222px) {
        padding: 1rem 5rem;
        column-gap: 5.5rem;
      }
      @media screen and (max-width: 1216px) {
        padding: 1rem 4.9rem;
        column-gap: 5.4rem;
      }
      @media screen and (max-width: 1209px) {
        padding: 1rem 4.8rem;
        column-gap: 5.3rem;
      }
      @media screen and (max-width: 1203px) {
        padding: 1rem 4.7rem;
        column-gap: 5.2rem;
      }
      @media screen and (max-width: 1197px) {
        padding: 1rem 4.6rem;
        column-gap: 5.1rem;
      }
      @media screen and (max-width: 1190px) {
        padding: 1rem 4.5rem;
        column-gap: 5rem;
      }
      @media screen and (max-width: 1184px) {
        padding: 1rem 4rem;
        column-gap: 5rem;
      }
      @media screen and (max-width: 1168px) {
        padding: 1rem 3.5rem;
        column-gap: 4.5rem;
      }
      @media screen and (max-width: 1136px) {
        padding: 1rem 3rem;
        column-gap: 4rem;
      }
      @media screen and (max-width: 1105px) {
        padding: 1rem 2.5rem;
        column-gap: 3.5rem;
      }
      @media screen and (max-width: 1105px) {
        padding: 1rem 2.5rem;
        column-gap: 3.5rem;
      }
      @media screen and (max-width: 1072px) {
        padding: 1rem 2rem;
        column-gap: 3rem;
      }
      @media screen and (max-width: 1040px) {
        padding: 1rem 1.5rem;
        column-gap: 2.5rem;
      }
      @media screen and (max-width: 1008px) {
        padding: 1rem 1rem;
        column-gap: 2rem;
      }

      @media screen and (max-width: 976px) {
        padding: 1rem 2.4rem;
        column-gap: 3.4rem;
      }
      @media screen and (max-width: 969px) {
        padding: 1rem 2.2rem;
        column-gap: 3.2rem;
      }
      @media screen and (max-width: 957px) {
        padding: 1rem 2rem;
        column-gap: 3rem;
      }
      @media screen and (max-width: 944px) {
        padding: 1rem 1.8rem;
        column-gap: 2.8rem;
      }
      @media screen and (max-width: 931px) {
        padding: 1rem 1.6rem;
        column-gap: 2.6rem;
      }
      @media screen and (max-width: 918px) {
        padding: 1rem 1.4rem;
        column-gap: 2.4rem;
      }
      @media screen and (max-width: 905px) {
        padding: 1rem 1.2rem;
        column-gap: 2.2rem;
      }
      @media screen and (max-width: 893px) {
        padding: 1rem;
        column-gap: 2rem;
      }
      @media screen and (max-width: 880px) {
        padding: 1rem 5.5rem;
        column-gap: 4.5rem;
      }
      @media screen and (max-width: 856px) {
        padding: 1rem 5rem;
        column-gap: 4rem;
      }
      @media screen and (max-width: 832px) {
        padding: 1rem 4.5rem;
        column-gap: 3.5rem;
      }
      @media screen and (max-width: 808px) {
        padding: 1rem 4rem;
        column-gap: 3rem;
      }
      @media screen and (max-width: 784px) {
        padding: 1rem 3.5rem;
        column-gap: 2.5rem;
      }
      @media screen and (max-width: 760px) {
        padding: 1rem 3rem;
        column-gap: 2rem;
      }
      @media screen and (max-width: 736px) {
        padding: 1rem 2.5rem;
        column-gap: 1.5rem;
      }
      @media screen and (max-width: 712px) {
        padding: 1rem 2rem;
        column-gap: 1rem;
      }
      @media screen and (max-width: 690px) {
        padding: 1rem 3rem;
        column-gap: 2.5rem;
      }
      @media screen and (max-width: 680px) {
        padding: 1rem 2.5rem;
        column-gap: 2rem;
      }
      @media screen and (max-width: 656px) {
        padding: 1rem 2rem;
        column-gap: 1.5rem;
      }
      @media screen and (max-width: 632px) {
        padding: 1rem 1.5rem;
        column-gap: 1rem;
      }
      @media screen and (max-width: 610px) {
        padding: 0.5rem;
        column-gap: 0;
        justify-content: center;
      }
    }
  }
}
