.madahiCard {
  padding-bottom: 1rem;
  margin: 1rem;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 0.5rem;

  @media screen and (min-width: 480px) and (max-width: 769px) {
    padding: 0.5rem 1rem;
  }

  @media screen and (max-width: 480px) {
    padding: 0.2rem 0.5rem;
  }

  &:hover {
    .podcastCard__button {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  // podcast cover image
  &__image {
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      height: 15rem;
      transition: transform 0.3s ease; /* Added transition */
    }

    &:hover img {
      transform: translateY(
        -20px
      ); /* Transition activated when hovering over the cards */
    }
  }

  // podcast play button
  &__button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #81abb9;
    color: white;
    text-align: center;
    padding: 0.5rem;
    opacity: 0;
    transition: 0.2s ease-out;
    transform: translate(0, 30%);
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: #658692;
    }
  }

  // podcast title
  &__title {
    padding: 0.7rem 0;
    text-align: center;
    font-weight: 600;
    margin: 0 1rem;

    @media screen and (max-width: 480px) {
      padding: 0;
    }

    span {
      font-size: clamp(0.7em, 1.2vw, 1em);
    }
  }

  // podcast detail: date and view
  &__detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7d7d7d;
    font-size: clamp(0.4em, 1vw, 0.7em);
    margin: 0 1rem;
    direction: rtl;

    &__date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.2rem;
    }

    &__time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0.2rem;
    }
  }
}
