:root {
  --primary-color: #00a65e; /* A primary color for consistency */
  --secondary-color: #56cc99; /* A secondary color for accents */
  --hover-color: #00e58c; /* A brighter color for hover states */
  --text-color: #ffffff; /* Text color for better readability */
  --box-shadow-color: rgba(0, 166, 94, 0.5); /* Box shadow color */
  --gradient-bg: linear-gradient(
    145deg,
    #00a65e,
    #00e58c
  ); /* Gradient background */
}

.wallet1,
.wallet2,
.wallet3 {
  --bg-color: var(--primary-color);
  --bg-color-light: var(--secondary-color);
  --text-color-hover: var(--text-color);
  --box-shadow-color: var(--box-shadow-color);
  --btn-color: var(--hover-color);
}

.courseSubscriptionCard {
  cursor: pointer;
  width: 300px; /* Increased width */
  height: 400px; /* Increased height */
  background: var(--gradient-bg); /* Gradient background */
  border-top-right-radius: 20px; /* Increased border radius */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 24px 46px rgba(0, 0, 0, 0.1); /* Stronger box shadow */
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  text-decoration: none;
  color: var(--text-color);

  /* Responsive design */
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  @media screen and (max-width: 576px) {
    justify-content: space-between;
  }
}

.courseSubscriptionCard:hover {
  -webkit-transform: translateY(-5px) scale(1.005) translateZ(0);
  transform: translateY(-5px) scale(1.005) translateZ(0);
}

.courseSubscriptionCard:hover .overlay {
  -webkit-transform: scale(4) translateZ(0);
  transform: scale(4) translateZ(0);
}

.courseSubscriptionCard:hover .circle {
  border-color: var(--bg-color);
  transition: all 0.1s;
  background: var(--bg-color);
}

.courseSubscriptionCard:hover .circle:after {
  background: var(--btn-color);
  background: var(--btn-color);
}

.courseSubscriptionCard:hover p {
  color: var(--text-color-hover);
}

.courseSubscriptionCard:hover .text {
  @media screen and (max-width: 768px) {
    color: var(--text-color-hover);
    margin-top: 0;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
}

.courseSubscriptionCard p {
  font-size: 1.3em;
  font-family: Peyda-Bold;
  color: var(--bg-color);
  margin-top: 30px;
  z-index: 10;
  -webkit-transition: color 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.courseSubscriptionCard .btn {
  cursor: pointer;
  padding: 0.6rem 0;
  width: 8rem;
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  border-radius: 0.3rem;
  outline: none;
  border: none;
  background-color: var(--btn-color);
  color: #fff;
  font-family: Peyda-Semibold;
  font-size: 1em;
  box-shadow: var(--box-shadow-color) 0px 5px 15px;
  box-shadow: var(--box-shadow-color) 0px 7px 29px 0px;
  transition: all 0.3s;

  @media screen and (max-width: 768px) {
    left: 77%;
  }
  @media screen and (max-width: 576px) {
    right: -1.7rem;
    width: 5.2rem;
    font-size: 0.8em;
  }
}

.courseSubscriptionCard:hover .btn {
  bottom: 5%;

  @media screen and (max-width: 768px) {
    bottom: 23%;
  }
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #303030;
  border: 2px solid var(--bg-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  // @media screen and (max-width: 576px) {
  //   width: 100px;
  //   height: 100px;
  // }

  &__text {
    z-index: 10000;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 3em;
      font-family: Peyda-Bold;
      color: #f1f3f5;
      height: 1em;

      @media screen and (max-width: 576px) {
        font-size: 2.3em;
      }
    }

    p {
      z-index: 10000;
      margin: 0;
      font-family: Peyda-Semibold;
      font-size: 1.2em;
      color: #f1f3f5;
    }
  }
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 7px;
  left: 7px;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;

  @media screen and (max-width: 768px) {
    left: 60%;
    top: 60%;
    transform: translate(-60%, -60%);
  }
  @media screen and (max-width: 576px) {
    left: 70%;
    top: 70%;
    transform: translate(-70%, -70%);
  }
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 7px;
  left: 7px;
  z-index: 0;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

  @media screen and (max-width: 768px) {
    left: 60%;
    top: 60%;
    transform: translate(-60%, -60%);
  }

  @media screen and (max-width: 576px) {
    left: 70%;
    top: 70%;
    transform: translate(-70%, -70%);
  }
}
