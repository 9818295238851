.profile__main__leftContainer__left {
  &__setpic {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &__logo {
      width: 7rem;
      height: 7rem;
      padding: 2rem;
      border-radius: 50%;
      background-color: #f1f3f4;

      & > svg {
        color: #a8b6ba;
      }

      &--pic {
        width: 2rem;
      }

      &--userpic {
        width: 100%;
      }
    }
  }

  &__setinfo {
    width: 100%;
    color: #000;
    display: flex;
    column-gap: 3rem;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 1rem;
    @media screen and (max-width: 970px) {
      padding: 1rem 0;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__inputContainer {
      width: 45%;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0rem 1rem;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 80%;
      }
      @media screen and (max-width: 576px) {
        padding: 0.5rem 0 2rem;
        width: 100%;
      }

      &__selectContainer {
        height: 2.5rem;
        padding-right: 0.5rem;
        direction: rtl;
        border: none;
        background: #f1f3f4;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        color: #151515;
        border-radius: 0.5rem;
        font-size: 0.7em;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--placeholder {
          color: #bec2c5;
          flex-basis: 100%;
          margin: 0;
        }

        & > svg {
          width: 1.5rem;
        }

        &__select {
          background-color: #f5f9fc;
          color: black;
          font-weight: bold;
          margin-top: 0.5rem;
          padding: 0.5rem;
          width: 100%;
          height: max-content;
          border-radius: 0.5rem;
          list-style: none;
          direction: rtl;
          position: absolute;
          top: 4.5rem;
          right: 0;

          &--text {
            color: #1d1d35;
            padding: 0.5rem;
            border-radius: 0.3rem;
            cursor: pointer;
            &:hover {
              background-color: #81abb9;
              color: #fff;
            }
          }
        }
      }

      &--label {
        padding: 0 0.5rem 0.3rem 0;
        color: #1d1d35;
      }

      &--input {
        height: 2.5rem;
        padding-right: 0.5rem;
        direction: rtl;
        border: none;
        background: #f1f3f4;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        color: #151515;
        border-radius: 0.5rem;
        font-size: 0.7em;
        outline: none;

        &::placeholder {
          color: #bec2c5;
        }
      }
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 2rem 1rem;

    &--button {
      color: #fff;
      width: 10rem;
      height: 3rem;
      border-radius: 0.5rem;
      border: none;
      background-color: #81abb9;
      font-weight: bold;
      transition: all ease-out 0.3s;
      &:hover {
        background-color: #6595a4;
        box-shadow: 0 0 0.25em rgba(9255, 9255, 255, 0.3),
          0 0.25em 1em rgba(0, 0, 0, 0.3);
      }
    }
  }
}
