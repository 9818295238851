.item {
  display: flex;
  padding: 1rem 1rem;
  flex: 1 0 32.4%;
  border-radius: 0.3rem;
  border: 1px solid #dde4ea;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  color: #272727;

  &:hover {
    background-color: #f8f9fa;
    border-color: #22a5ad;
  }

  &:hover &--number {
    background-color: #22a5ad;
    color: #f4f5f6;
  }

  &:hover &--englishTranslate {
    color: #22a5ad;
  }
  &:hover &--ayeh {
    color: #22a5ad;
  }

  &--number {
    background-color: #f4f5f6;
    transform: rotate(45deg);
    border-radius: 0.3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;

    & > span {
      transform: rotate(-45deg);
    }
  }

  &--name {
    font-family: "Noto Naskh Arabic", serif;
    font-size: 1em;
  }

  &__arabicName {
    text-align: right;
    flex: 1;
    padding-right: 1rem;
  }

  &__englishNames {
    text-align: left;
    flex: 1;
  }

  &--englishName,
  &--englishTranslate {
    font-family: "Ubuntu", sans-serif;
  }

  &--englishTranslate,
  &--ayeh {
    color: #767575;
    font-size: 0.8em;
    font-weight: bold;
  }

  &--ayeh {
    word-spacing: 2px;
    font-size: 0.75em;
  }
}
