.auth__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;

  &__form {
    width: 30%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #81abb9;
    padding: 2rem 4rem;
    border-radius: 0.5rem;
    color: #fff;

    @media screen and (min-width: 1091px) and (max-width: 1208px) {
      width: 40%;
      padding: 2rem 3rem;
    }
    @media screen and (min-width: 1038px) and (max-width: 1091px) {
      width: 40%;
      padding: 1.5rem 2.5rem;
    }
    @media screen and (min-width: 887px) and (max-width: 1038px) {
      width: 40%;
      padding: 1.5rem 2.5rem;
    }
    @media screen and (min-width: 800px) and (max-width: 887px) {
      width: 45%;
      padding: 1.5rem 2.5rem;
    }
    @media screen and (min-width: 760px) and (max-width: 800px) {
      padding: 1.5rem 2.4rem;
      width: 45%;
    }
    @media screen and (min-width: 730px) and (max-width: 760px) {
      padding: 1.5rem 2.3rem;
      width: 50%;
    }
    @media screen and (min-width: 700px) and (max-width: 730px) {
      padding: 1.5rem 2.1rem;
      width: 50%;
    }
    @media screen and (min-width: 596px) and (max-width: 700px) {
      padding: 1.5rem 2rem;
      width: 55%;
    }
    @media screen and (min-width: 497px) and (max-width: 596px) {
      padding: 1.1rem 1.4rem;
      width: 60%;
    }
    @media screen and (min-width: 423px) and (max-width: 497px) {
      padding: 0.5rem 0.8rem;
      width: 70%;
    }
    @media screen and (min-width: 379px) and (max-width: 423px) {
      padding: 1.1rem 1.4rem;
      width: 70%;
    }

    @media screen and (min-width: 250px) and (max-width: 379px) {
      padding: 1rem 1.1rem;
      width: 90%;
    }

    @media (max-height: 550px) {
      height: 80%;
    }

    @media (max-height: 400px) {
      height: 90%;
    }

    &--title {
      font-size: 1.5em;
      font-weight: 700;
      direction: rtl;

      @media screen and (max-width: 960px) {
        font-size: 1.3em;
      }
      @media screen and (max-width: 558px) {
        font-size: 1.1em;
      }

      span {
        color: #002733;
      }
    }

    &--codeTitle {
      font-size: 1.3rem;
      font-weight: 700;
      word-spacing: 3px;

      span {
        color: #002733;
      }
    }

    &--text {
      font-weight: 600;
      color: #f5f5f5;
      word-spacing: 2px;
      margin: 0.5rem 0rem;
      @media screen and (min-width: 379px) and (max-width: 1038px) {
        font-size: 13px;
      }
      @media screen and (min-width: 360px) and (max-width: 379px) {
        font-size: 12px;
      }
    }

    &__inputContainer {
      text-align: right;
      width: 80%;

      &--input {
        height: 2.5rem;
        width: 100%;
        outline: none;
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        text-align: center;
        color: #002733;

        &::placeholder {
          font-size: 0.9em;
        }
      }
      &--loginInput {
        margin-top: 1rem;
      }

      &__errorContainer {
        margin-bottom: 0.8rem;
        &--errorText {
          margin-top: 4px;

          font-weight: 700;
          font-size: 0.8em;
          color: #f9f871;
          margin-bottom: 0;

          span {
            color: #fff;
          }
        }
      }

      &--errorText {
        margin-top: 4px;

        font-weight: 700;
        font-size: 0.8em;
        color: #f9f871;
        margin-bottom: 0;

        span {
          color: #fff;
        }
      }

      &--forgotText {
        color: #fff;
        font-size: 0.7em;
        font-weight: 700;
        margin: 1rem 0;

        @media (max-width: 596px) {
          font-size: 0.6em;
        }

        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__resendCode {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      &--button {
        background: transparent;
        border: none;
        outline: none;
        color: #002733;
        font-weight: bold;
        flex-basis: 60%;

        &:disabled {
          color: #5e8795;
          cursor: not-allowed;
        }
      }

      span {
        text-align: right;
        flex-basis: 40%;
      }
    }

    &--button {
      background-color: #5e8795;
      border: none;
      outline: none;
      border-radius: 2rem;
      color: #fff;
      padding: 0.5rem 0.8rem;
      font-weight: 900;
      font-size: 0.9em;
      word-spacing: 3px;
      width: 80%;
      transition: all 0.5s;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #002733;
      }

      & {
        @media screen and (max-width: 800px) {
          padding: 0.5rem;
          font-size: 0.8em;
        }
      }
    }
    &--progressIcon {
      background-color: #fff;
      border: none;
      outline: none;
      border-radius: 2rem;
      padding: 0.5rem 0.8rem;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
