.surah {
  direction: rtl;
  margin-top: 4rem;

  &__drawer {
    height: 2.85rem;
    position: fixed;
    top: 3.5rem;
    z-index: 2;
    background-color: #fff;
    width: 100%;
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    @media screen and (max-width: 768px) {
      top: 3.5rem;
    }

    &--surahName {
      font-size: 1.5em;
      font-family: surahnames;
      font-weight: 100;
    }

    &__button {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-family: "Ubuntu", sans-serif;
      font-size: 1em;
      color: #303030;
    }

    &__container {
      position: fixed;
      top: 0;
      left: -17rem;
      height: 100%;
      width: 17rem;
      direction: ltr;
      padding: 7rem 1rem 0;
      border-right: 1px solid #e9e9e9;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      background-color: #fff;
      z-index: 1;

      ::-webkit-scrollbar {
        width: 0.3rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 0.3rem;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #e9e9e9;
        border-radius: 0.3rem;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #e9e9e9;
      }
    }

    &__containerShow {
      left: 0;
    }
  }

  &__content {
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    padding-top: 5rem;
    position: relative;
    min-height: 100vh;

    &__loading {
      position: fixed;
      top: 6.4rem;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      color: #258c91;
    }

    &__start {
      &--name {
        // font-family: "Noto Naskh Arabic", serif;
        font-family: surahnames;
        font-size: 4em;
        font-weight: 100;

        @media screen and (max-width: 1024px) {
          font-size: 3em;
        }
        @media screen and (max-width: 768px) {
          font-size: 2em;
        }
      }
    }

    &__verses {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin: 0 9rem;
      @media screen and (max-width: 1024px) {
        margin: 0 2rem;
      }

      @media screen and (max-width: 768px) {
        margin: 0 4rem;
      }

      &__verse {
        padding: 3rem 0 2rem;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        font-size: 1.2em;
        border-bottom: 1px solid #e9e9e9;
        direction: rtl;
        font-family: "Markazi Text", serif;

        &__arabic {
          font-family: "Noto Naskh Arabic", serif;
          text-align: right;
          font-size: 1.3em;
          display: flex;
          align-items: center;
          color: #202020;
          @media screen and (max-width: 768px) {
            font-size: 1em;
          }

          &__number {
            margin-right: 0.5rem;
            display: inline-block;
            &__icon {
              font-size: 1.3em;
              color: #a1a1a1;
              position: relative;

              &::before {
                content: "۝";
                color: #404040;
              }

              &--num {
                font-family: "Noto Naskh Arabic", serif;
                font-size: 0.6em;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
                color: #202020;
              }
            }
          }
        }

        &--translation {
          text-align: left;
          direction: rtl;
          font-family: "Markazi Text", serif;
          color: #202020;
          font-size: 1.25em;

          @media screen and (max-width: 768px) {
            font-size: 0.8em;
          }
        }
      }
    }
  }
  .marginLeft {
    margin-left: 17rem;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }
}
