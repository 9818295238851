.profile {
  background-color: #f5f9fc;
  width: 100%;
  min-height: 100vh;
  @media screen and (min-height: 915px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: max-content;
  }

  &__main {
    direction: rtl;
    text-align: right;
    color: #f0f0f0;
    margin-left: auto;
    margin-right: auto;
    padding: 7rem 4rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 970px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
    @media screen and (min-height: 915px) {
      margin-left: 0;
      margin-right: 0;
      flex: 1;
    }
    @media screen and (min-height: 1200px) {
      margin-left: 0;
      margin-right: 0;
      flex: 1;
      padding: 7rem 3rem;
    }

    &__leftContainer {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 4rem;
      padding-left: 2rem;
      @media screen and (max-width: 970px) {
        width: 100%;
        padding: 0;
      }
      &__left {
        flex: 1;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }
  }
}
