.teacher {
  padding: 8rem 6%;
  display: flex;
  flex-direction: row-reverse;
  gap: 1.5rem;
  background-color: #f3f4f6;

  @media screen and (max-width: 920px) {
    padding: 8rem 0;
  }

  // sidebar
  &__sidebar {
    width: 25%;
    height: fit-content;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    padding-bottom: 2rem;

    // teacher image
    &--icon {
      padding: 2rem 25%;

      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    // teacher name
    &--name {
      margin-bottom: 0.5rem;
      span {
        color: #1d1d35;
        font-size: clamp(0.6em, 2vw, 1.4em);
      }
    }

    // teacher about
    &--about {
      margin-bottom: 1rem;
      p {
        color: #64748b;
        line-height: 2em;
        font-size: clamp(0.4em, 2vw, 0.7em);
      }
    }

    // teacher description
    &--description {
      margin-bottom: 1rem;
      p {
        color: #64748b;
        line-height: 2em;
        font-size: clamp(0.4em, 2vw, 0.9em);
      }
    }
  }

  // main
  &__main {
    width: 75%;

    // filter
    &__filter {
      width: 100%;
      margin-bottom: 1rem;
      background-color: #fff;
      border-radius: 1rem;
      padding: 1rem 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;

      // filter box title
      &__title {
        display: flex;
        gap: 0.6rem;
        font-size: clamp(0.6em, 2vw, 1em);

        // icon
        &--icon {
          font-size: clamp(1em, 2vw, 1.4em);
        }
      }

      // filter box butons
      &__items {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 0.2rem;

        @media screen and (max-width: 480px) {
          margin-right: 0;
        }

        &__btn {
          padding: 0.5rem 0.8rem;
          border-radius: 0.5rem;
          cursor: pointer;
          color: #64748b;
          font-size: clamp(0.4em, 2vw, 1em);

          &:hover {
            background-color: #f3f4f6;
            color: black;
          }

          // &:first-child {

          // }
        }
      }
    }

    // container
    &__container {
      width: 100%;
      padding-bottom: 10rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: 651px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }

      @media screen and (max-width: 650px) {
        grid-template-columns: 1fr;
      }
    }
  }
}

.filterActiveTab {
  color: black;
  background-color: #f3f4f6;
}
