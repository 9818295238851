.courseBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;

  &__btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #0ea5e9;

    &:hover {
      color: #0b73a4;
    }

    a {
      text-decoration: none;
      color: #0ea5e9;
      font-size: clamp(0.5em, 2vw, 1em);

      &:hover {
        color: #0b73a4;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.6rem;

    h2 {
      font-family: Peyda-Bold;
      font-size: clamp(1.2em, 2vw, 3.6em);
    }

    p {
      font-family: Peyda-light;
      color: #353535;
      font-size: clamp(0.8em, 2vw, 1.2em);
    }
  }
}
