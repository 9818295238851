.character {
  @media screen and (max-height: 600px) {
    height: 40rem;
  }
  @media screen and (max-width: 785px) {
    height: 55rem;
  }
  @media screen and (max-width: 521px) {
    height: 75rem;
  }
  @media screen and (max-width: 353px) {
    height: 120rem;
  }

  &__contents {
    @media screen and (max-width: 1555px) {
      padding: 1rem 4rem;
      column-gap: 3rem;
    }
    @media screen and (max-width: 1520px) {
      padding: 1rem 3.8rem;
      column-gap: 2.8rem;
    }
    @media screen and (max-width: 1498px) {
      padding: 1rem 3.6rem;
      column-gap: 2.6rem;
    }
    @media screen and (max-width: 1475px) {
      padding: 1rem 3.4rem;
      column-gap: 2.4rem;
    }
    @media screen and (max-width: 1453px) {
      padding: 1rem 3.2rem;
      column-gap: 2.2rem;
    }
    @media screen and (max-width: 1430px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 1408px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 1385px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 1363px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 1341px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 1318px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 1296px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 1265px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 1242px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 1219px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 1197px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 1175px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }
    @media screen and (max-width: 1152px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 1145px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 1126px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 1107px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 1088px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 1065px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 1045px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 1025px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 1006px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 987px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 970px) {
      padding: 1rem 4rem;
      column-gap: 3rem;
    }
    @media screen and (max-width: 960px) {
      padding: 1rem 3.8rem;
      column-gap: 2.8rem;
    }
    @media screen and (max-width: 945px) {
      padding: 1rem 3.6rem;
      column-gap: 2.6rem;
    }
    @media screen and (max-width: 928px) {
      padding: 1rem 3.4rem;
      column-gap: 2.4rem;
    }
    @media screen and (max-width: 912px) {
      padding: 1rem 3.2rem;
      column-gap: 2.2rem;
    }
    @media screen and (max-width: 880px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 865px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 848px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 832px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 816px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 800px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 785px) {
      padding: 1rem 3.5rem;
      column-gap: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      padding: 1rem 3.2rem;
      column-gap: 2.2rem;
    }
    @media screen and (max-width: 750px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 736px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 723px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 710px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 697px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 685px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }
    @media screen and (max-width: 675px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 675px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 665px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 651px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 638px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 625px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 613px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 600px) {
      padding: 1rem 2rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 585px) {
      padding: 1rem 1.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 571px) {
      padding: 1rem 1.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 558px) {
      padding: 1rem 1.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 545px) {
      padding: 1rem 1.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 533px) {
      padding: 1rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 520px) {
      padding: 1rem 3.5rem;
      column-gap: 2.5rem;
    }
    @media screen and (max-width: 505px) {
      padding: 1rem 3.2rem;
      column-gap: 2.2rem;
    }
    @media screen and (max-width: 490px) {
      padding: 1rem 3rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 480px) {
      padding: 1rem 2.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 470px) {
      padding: 1rem 2.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 461px) {
      padding: 1rem 2.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 451px) {
      padding: 1rem 2.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 441px) {
      padding: 1rem 2rem;
      column-gap: 1rem;
    }

    @media screen and (max-width: 432px) {
      padding: 1rem 2.5rem;
      column-gap: 2.5rem;
    }
    @media screen and (max-width: 425px) {
      padding: 1rem 2.2rem;
      column-gap: 2.2rem;
    }
    @media screen and (max-width: 410px) {
      padding: 1rem 2rem;
      column-gap: 2rem;
    }
    @media screen and (max-width: 400px) {
      padding: 1rem 1.8rem;
      column-gap: 1.8rem;
    }
    @media screen and (max-width: 390px) {
      padding: 1rem 1.6rem;
      column-gap: 1.6rem;
    }
    @media screen and (max-width: 381px) {
      padding: 1rem 1.4rem;
      column-gap: 1.4rem;
    }
    @media screen and (max-width: 371px) {
      padding: 1rem 1.2rem;
      column-gap: 1.2rem;
    }
    @media screen and (max-width: 361px) {
      padding: 1rem;
      column-gap: 1rem;
    }
    @media screen and (max-width: 352px) {
      padding: 1rem;
      column-gap: 1rem;
      justify-content: center;
    }

    &__card {
      background-color: rgba(255, 255, 255, 0.3);
      transition: all 0.5s;
      @media screen and (max-width: 1296px) {
        width: 8.5rem;
        height: 12.5rem;
      }
      @media screen and (max-width: 1152px) {
        width: 10rem;
        height: 14rem;
      }
      @media screen and (max-width: 1088px) {
        width: 8.5rem;
        height: 12.5rem;
      }
      @media screen and (max-width: 785px) {
        width: 10rem;
        height: 14rem;
      }
      @media screen and (max-width: 675px) {
        width: 8.5rem;
        height: 12.5rem;
      }
      @media screen and (max-width: 600px) {
        margin: 0.5rem;
      }
      @media screen and (max-width: 520px) {
        width: 10rem;
        height: 14rem;
      }
      @media screen and (max-width: 432px) {
        width: 8.5rem;
        height: 12.5rem;
      }
      @media screen and (max-width: 352px) {
        width: 10rem;
        height: 14rem;
      }

      &__link {
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        position: relative;
        background-repeat: no-repeat;
        font-family: Peyda;
        line-height: 1.4;
        text-decoration: none;

        &--img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          object-fit: contain;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }

      &__desc {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;

        &--text {
          color: #000;
          margin: 0;
          position: absolute;
          font-size: 18px;
        }
      }
    }
  }
}
