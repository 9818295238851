.articleHeader {
  width: 100%;
  border-radius: 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #81abb9;
  word-spacing: 0.4rem;

  @media screen and (max-width: 568px) {
    padding: 1.5rem;
  }

  h2 {
    font-family: Peyda-Bold;
    font-size: clamp(1em, 2vw, 3.6em);
    color: #fff;
  }
}
