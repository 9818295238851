.serialSidebar {
  // sidebar
  // position: sticky;
  top: 0;
  width: 30%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 0 10%;
  }

  // box
  &__box {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    // info
    &__info {
      display: flex;
      flex-direction: row-reverse;
      gap: 0.5rem;

      // student and rate box css
      &__student,
      &__rate {
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f3f4f6;
        width: 50%;
        border-radius: 1rem;

        // text
        &__text {
          span {
            font-family: Peyda-Bold;
            font-size: clamp(0.6em, 2vw, 1.2em);
          }

          p {
            color: #7a7a7a;
            font-size: clamp(0.4em, 2vw, 0.8em);
          }
        }

        // icon
        &--icon {
          font-size: clamp(1.4em, 2vw, 2.4em);
        }
      }

      // student
      &__student {
        // icon
        &--icon {
          color: #2ed573;
        }
      }

      // rate
      &__rate {
        // icon
        &--icon {
          font-size: clamp(1.4em, 2vw, 2.4em);
          color: rgb(251, 191, 36);
        }
      }
    }

    // progress
    &__progress {
      width: 100%;
      height: 100px;
      margin-top: 1rem;
      border-radius: 1rem;
      padding: 1rem;

      // header
      &__header {
        display: flex;
        justify-content: space-between;
        color: #1d1d35;
        font-size: clamp(0.6em, 2vw, 1.2em);
        margin-bottom: 1.5rem;
      }

      // bar
      &__bar {
        position: relative;
        width: 100%;
        height: 0.7rem;
        background-color: #1d1d35;
        border-radius: 0.5rem;
        overflow: hidden;

        // value
        &--value {
          position: absolute;
          top: 0;
          right: 0;
          width: 45%;
          height: 100%;
          border-radius: 1rem;
          // background-color: #2ed573;
          background-color: #81abb9;
        }
      }
    }

    // ------------------------------------
    // teacher box

    &__teacherImage {
      // padding: ;
      border-radius: 50%;
      margin: 1rem auto;
      width: 7rem;
      height: 7rem;
    }

    // teacherTitle
    &__teacherName {
      font-size: clamp(0.8em, 2vw, 1.4em);
      transition: all ease-in 0.1s;

      &:hover {
        color: #81abb9;
      }
    }

    // teacherTitle
    &__teacherTitle {
      font-size: clamp(0.6em, 2vw, 1em);
      color: #7a7a7a;
    }

    // ------------------------------------
    // link box
    &__linkHeader {
      text-align: right;
      padding-right: 1rem;
      margin-bottom: 1rem;
      span {
        font-size: clamp(0.6em, 2vw, 1.4em);
        color: #1d1d35;
      }
    }

    &__linkBox {
      padding: 1rem;
      background-color: #f3f4f6;
      border-radius: 0.8rem;
      display: flex;
      justify-content: space-between;
      color: #7a7a7a;
      font-family: Peyda-Light;
      font-size: clamp(0.6em, 2vw, 1.2em);

      // icon
      &--icon {
        cursor: pointer;
      }
    }
  }
}
