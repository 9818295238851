.serial {
  padding: 0 10%;
  padding-top: 8rem;
  background-color: #f3f4f6;

  @media screen and (max-width: 1250px) {
    padding: 0 2%;
    padding-top: 8rem;
  }

  // -----
  // header
  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
      padding: 0 10%;
    }

    // -----
    // cover
    &__cover {
      @media screen and (max-width: 1000px) {
        padding: 0 10%;
      }

      @media screen and (max-width: 768px) {
        padding: 0;
      }

      img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        border-radius: 1.5rem;
      }
    }

    // -----
    // detail
    &__detail {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // text
      &__text {
        @media screen and (max-width: 1000px) {
          margin-bottom: 2rem;
        }

        // title
        &__title {
          font-family: Peyda-Bold;
          font-size: clamp(2em, 2vw, 3em);
          margin-bottom: 1rem;
          color: #1d1d35;
        }

        // description
        &__description {
          font-family: Peyda-light;
          font-size: clamp(0.8em, 2vw, 1.5em);
          color: #1d1d35b1;
        }
      }

      // signUp
      &__signUp {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // sign up button
        &--btn {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-family: Peyda-Bold;
          font-size: clamp(0.6em, 2vw, 1.4em);
          background-color: #1d1d35;
          color: #fff;
          padding: 1rem;
          border-radius: 0.7rem;
          cursor: pointer;
          transition: all ease-in 0.2s;

          // hover
          &:hover {
            box-shadow: 0 0 3px black;
            transform: translate(0, -5px);
          }
        }

        // price
        &__price {
          font-family: Peyda-Bold;
          font-size: clamp(1.6em, 2vw, 3em);
          color: #1d1d35;
        }
      }
    }
  }

  // -------
  // container
  &__container {
    display: flex;
    gap: 1rem;

    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
    }

    // main
    &__main {
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0 10%;
      }

      // info
      &__info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
