.profile__main__right {
  margin-left: 1rem;
  width: 27%;

  @media screen and (max-width: 970px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  }

  &__container {
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0.5rem;

    &__userinfo {
      display: flex;
      align-items: center;
      padding: 2rem 1rem 1.5rem;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      @media screen and (max-width: 1050px) {
        padding-right: 0;
      }
      @media screen and (max-width: 576px) {
        flex-direction: column;
      }
      @media screen and (max-width: 280px) {
        padding: 1rem 0.5rem;
      }

      &--pic {
        width: 5rem;
        background-color: #f1f3f4;
        padding: 0.8rem;
        margin-left: 0.5rem;
        border-radius: 50%;
        @media screen and (max-width: 576px) {
          margin-left: 0;
          margin-bottom: 0.5rem;
        }
        @media screen and (max-width: 280px) {
          width: 4rem;
          margin-left: 1rem;
        }

        & > svg {
          color: #a8b6ba;
        }
      }

      &--text {
        font-family: Peyda;
        color: #374955;
      }
    }

    &__menu {
      padding: 1rem;
      // @media screen and (max-width: 970px) {
      //   display: flex;
      //   flex-wrap: wrap;
      //   align-items: center;
      //   justify-content: flex-start;
      // }
      // @media screen and (max-width: 576px) {
      //   flex-direction: column;
      //   align-items: flex-start;
      // }
      @media screen and (max-width: 1050px) {
        padding: 1rem 0;
      }
      @media screen and (min-height: 1200px) {
        flex-direction: column;
      }

      &__item {
        list-style-type: none;
        cursor: pointer;
        &:hover {
          background-color: #81abb9;
          border-radius: 0.5rem;
          * {
            color: #fff;
          }
        }

        &__link,
        &__exit {
          display: flex;
          text-decoration: none;
          background-color: transparent;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 0.7rem;
          &--logo {
            font-size: 2rem;
            margin-left: 1rem;
            color: #374955;
          }

          &--text {
            color: #374955;
          }
        }
      }
    }
  }
}
