.courseSubscription {
  margin-top: 5rem;

  &__Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &--title {
      font-size: clamp(1.3em, 2vw, 2em);
      color: #000000;
    }
  }

  hr {
    background-color: #60606030; //Chrome and Safari
    border-color: #60606030; //Firefox and Opera
    color: #60606030; //IE7+
    width: 100%;
  }

  &__body {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      row-gap: 1rem;
    }

    @media screen and (max-width: 376px) {
      padding: 0;
    }
  }

  &__footnote1 {
    padding: 1rem 3rem;
    direction: rtl;
    display: flex;
    align-items: flex-start;
    color: #000000;
    font-size: 0.95em;

    svg {
      font-size: 1.6em;
      height: fit-content;
      color: #1ee494;
    }
  }

  &__footnote2 {
    padding: 0 3rem;
    direction: rtl;
    display: flex;
    align-items: flex-start;
    color: #000000;
    font-size: 0.95em;

    svg {
      font-size: 2em;
      height: fit-content;
      color: #1ee494;
    }
  }
}
