.podcastPlay {
  margin-top: 4rem;
  background-color: #f3f4f6;
  //   padding: 0 10%;

  @media screen and (max-width: 920px) {
    padding: 0;
  }

  // banner
  &__banner {
    position: relative;
    width: 100%;
    height: 600px;
    background: url("../../assets/podcasts/podcast.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 10%;

    // filter
    &--filter {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000000;
      opacity: 0.5;
    }

    // podcast detail
    &__detail {
      z-index: 2;
      text-align: right;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      color: #fff;

      // title
      &--title {
        font-family: Peyda-Bold;
        font-size: clamp(2.8em, 2vw, 4em);
      }

      // info
      &--info {
        line-height: 3rem;
        font-size: clamp(0.8em, 2vw, 1.6em);
      }
    }

    // podcast image cover
    &--cover {
      z-index: 2;
      padding: 0 9%;
      width: 40%;

      img {
        border-radius: 0.5rem;
        border: 2px solid #fff;
        width: 100%;
        //   width: 20rem;
        height: 18rem;
      }
    }
  }

  //   player
  &__player {
    position: relative;
    z-index: 5;
    width: 85%;
    margin: 0 auto;
    margin-top: -5rem;
    border-radius: 1rem;
    // border: 2px solid #000;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  //   container
  &__container {
    width: 85%;
    margin: 0 auto;
    height: 1000px;
    background-color: #f3f4f6;
  }
}
