.profile__main__leftContainer__left {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    &--title {
      font-size: 1.3em;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #1d1d35;
      margin: 0.5rem;
      @media screen and (max-width: 970px) {
        font-size: 1.3em;
      }
    }

    &--btn {
      border: none;
      color: #fff;
      font-size: 1em;
      padding: 0.3rem 0.7rem;
      cursor: pointer;
      width: 7rem;
      height: 2.5rem;
      border-radius: 9rem;
      background-color: #81abb9;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      margin: 0.5rem;
      transition: all ease 0.3s;

      @media screen and (max-width: 970px) {
        width: 5rem;
        padding: 0.2rem;
      }

      &:hover {
        background-color: #6595a4;
      }

      &--svg {
        width: 1.5rem;
        height: 100%;
      }
    }
  }

  &__choice {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;

    &__sectionmark {
      padding: 0.5rem 1rem;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      &__logo {
        width: 3rem;
        height: 3rem;
        padding: 0.6rem;
        border-radius: 50%;
        color: #a8b6ba;
        background-color: #f1f3f4;
        display: grid;
        align-items: center;
        align-content: center;

        &--pic {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &--text {
      color: #a9a9a9;
      font-size: 0.8rem;
      margin: 0;
    }
  }
}
