@import "../../App.scss";

.terms__container {
  padding-top: 4.5rem;
  padding-bottom: 6rem;
  color: rgb(0, 0, 0);
  text-align: right;
  direction: rtl;
}
@media screen and (max-width: 399px) {
  .terms__container {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 420px) {
  .terms__container {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
}

.terms__content {
  max-width: 67.5rem;
  margin: 0 auto;
  position: relative;
  font-size: 0.875rem;
  z-index: 2;
}

.terms__content--title {
  font-size: 1.5rem;
  font-weight: bold;
}
@media screen and (max-width: 281px) {
  .terms__content--title {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 385px) {
  .terms__content--title {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 420px) {
  .terms__content--title {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 421px) and (max-width: 470px) {
  .terms__content--title {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 470px) and (max-width: 523px) {
  .terms__content--title {
    font-size: 1.9rem;
  }
}
@media screen and (min-width: 524px) and (max-width: 592px) {
  .terms__content--title {
    font-size: 2.15rem;
  }
}

.terms__content--p {
  padding-bottom: 4px;
  font-size: 1.1rem;
  line-height: 30px;
}
@media screen and (max-width: 281px) {
  .terms__content--p {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 385px) {
  .terms__content--p {
    font-size: 0.95rem;
  }
}
@media screen and (min-width: 386px) and (max-width: 399px) {
  .terms__content--p {
    font-size: 1rem;
  }
}
@media screen and (min-width: 471px) and (max-width: 523px) {
  .terms__content--p {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 524px) and (max-width: 592px) {
  .terms__content--p {
    font-size: 1.3rem;
  }
}

.terms__content--a {
  text-decoration: none;
}

.terms {
  /* font-family: dana,sans-serif;
        background-color: #1e2b35;
        color: #fff;
        padding: 1.5rem;
        padding-bottom: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        direction: rtl; */
  width: 100%;
  display: block;
}

.terms__footer {
  /* position: fixed; */
  background-color: #151e25;
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  opacity: 1;
  z-index: 1200;
}

.terms__footer--style,
.terms__footer--right {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
}
@media screen and (max-width: 420px) {
  .terms__footer--style {
    padding: 0.5rem 0.1rem;
  }
}

.terms__footer--right {
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
}
@media screen and (max-width: 281px) {
  .terms__footer--right {
    padding: 0.5rem 0.1rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 385px) {
  .terms__footer--right {
    padding: 0.5rem 0.1rem;
  }
}
@media screen and (min-width: 386px) and (max-width: 420px) {
  .terms__footer--right {
    padding: 1rem 0.1rem;
  }
}

.terms__footer--right--item {
  margin: 0;
  margin-left: 2rem;
  margin-right: 2rem;
}
@media screen and (max-width: 385px) {
  .terms__footer--right--item {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
}
@media screen and (min-width: 386px) and (max-width: 800px) {
  .terms__footer--right--item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.terms__footer--right--item--a {
  color: gray;
  font-size: 1.1rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
@media screen and (max-width: 281px) {
  .terms__footer--right--item--a {
    font-size: 0.65rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 329px) {
  .terms__footer--right--item--a {
    font-size: 0.69rem;
  }
}
@media screen and (min-width: 330px) and (max-width: 385px) {
  .terms__footer--right--item--a {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 386px) and (max-width: 399px) {
  .terms__footer--right--item--a {
    font-size: 0.95rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 419px) {
  .terms__footer--right--item--a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 420px) and (max-width: 460px) {
  .terms__footer--right--item--a {
    font-size: 1.08rem;
  }
}
@media screen and (min-width: px) and (max-width: px) {
  .terms__footer--right--item--a {
    font-size: rem;
  }
}

.terms__footer--divider {
  width: 90%;
  padding: 0 auto;
  /* padding-top: 1.5rem; */
  padding-bottom: 1rem;
  color: hsl(0, 0%, 100%);
  border-bottom: 1px solid;
  opacity: 0.2;
}

.terms__footer--left {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.terms__footer--left--p {
  font-size: 1rem;
  margin-top: revert;
  margin-bottom: 0;
}
@media screen and (max-width: 281px) {
  .terms__footer--left--p {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 282px) and (max-width: 350px) {
  .terms__footer--left--p {
    font-size: 0.55rem;
  }
}
@media screen and (min-width: 351px) and (max-width: 394px) {
  .terms__footer--left--p {
    font-size: 0.6rem;
  }
}
@media screen and (min-width: 395px) and (max-width: 420px) {
  .terms__footer--left--p {
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 422px) and (max-width: 522px) {
  .terms__footer--left--p {
    font-size: 0.76rem;
  }
}
@media screen and (min-width: 523px) and (max-width: 600px) {
  .terms__footer--left--p {
    font-size: 0.85rem;
  }
}

.terms__footer--left--p--designer {
  font-size: 0.8rem;
  color: gray;
  margin: 0.4rem;
}
