.channelsContainer {
  margin: 0 0 2rem;
  padding: 0.5rem 0;
  background-color: #81abb9;
  //   background-image: linear-gradient(to top left, #149e72, #43b156);
  border-radius: 1rem;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
}

.splide__arrow--next {
  left: 0rem !important;
  background-color: transparent;
}

.splide__arrow--prev {
  right: 0rem !important;
  background-color: transparent;
}

.channels {
  padding: 0.8rem 0;
  width: 100%;
  font-family: Peyda;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0.3rem;
  animation: slideIn 1s ease-in-out;

  &--logo {
    width: 4rem;
    height: 4rem;
  }

  &--name {
    margin: 0;
    font-family: peyda-light;
    font-weight: 600;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(500%);
    opacity: 0;
  }

  50% {
    transform: translateX(-100%);
    opacity: 0.5;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
