.podcast__container {
  @media screen and (max-width: 885px) {
    justify-content: space-evenly;
  }
  @media screen and (max-width: 825px) {
    padding: 3rem 1rem;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    margin: 1rem 0;
    background: #3a4861;
    border-radius: 0.3rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9cadbc;
    border-radius: 0.3rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9cadbc;
  }

  &__playList,
  &__playerContainer {
    border: 2px solid #36425a;
  }

  &__playList {
    @media screen and (max-width: 825px) {
      margin-bottom: 1rem;
      flex-direction: row-reverse;
      overflow-y: hidden;
    }

    .selected {
      background-image: linear-gradient(to top left, #149e72, #43b156);

      p {
        color: #f5f9fc;
      }
      h3 {
        font-weight: bold;
      }
    }

    .selectedNumber {
      background-image: linear-gradient(to top right, #52b878, #59ba73);
    }

    &__item {
      direction: rtl;
      @media screen and (max-width: 1410px) {
        padding: 1rem;
      }
      @media screen and (max-width: 825px) {
        height: 8rem;
        margin: 0 0 0 0.5rem;
        padding: 0.5rem;
        border-radius: 1rem;
        flex-direction: column-reverse;
      }

      &--number {
        @media screen and (max-width: 825px) {
          width: 8rem;
          height: 2rem;
          margin-left: 0;
        }
        @media screen and (max-width: 510px) {
          width: 5.5rem;
        }
      }

      &__details {
        @media screen and (max-width: 1410px) {
          align-items: flex-start;
          flex-direction: column;
        }
        @media screen and (max-width: 825px) {
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        &--name {
          @media screen and (max-width: 1410px) {
            padding: 0;
          }
          @media screen and (max-width: 510px) {
            font-size: 1rem;
          }
        }

        &--englishName {
          @media screen and (max-width: 1410px) {
            padding-bottom: 0.3rem;
          }
          @media screen and (max-width: 510px) {
            font-size: 0.9em;
          }
        }
      }
    }
  }

  &__playerContainer {
    &__quran {
      @media screen and (max-width: 825px) {
        height: 40rem;
      }
      &__text {
        &:not(:last-child) {
          border-bottom: 1px solid #f5f9fc;
        }

        &--tarjomeh {
          direction: rtl;
          font-family: "Markazi Text", serif;
          font-size: 1.2em;
        }
      }
    }

    &__player {
      // @media screen and (min-width: 420px) and (max-width: 510px) {
      //   padding: 2.5rem 1rem;
      // }
      &__controls {
        @media screen and (max-width: 340px) {
          flex-direction: column;
        }

        & > * {
          background-color: #283650;
          border-radius: 0.5rem;
        }

        &__title {
          &--english {
            font-family: "Ubuntu", sans-serif;
          }
          &--arabic {
            font-family: "Noto Naskh Arabic", serif;
          }

          @media screen and (max-width: 576px) {
            display: none;
          }
        }

        &__buttons {
          @media screen and (max-width: 825px) {
            flex-basis: 23%;
          }
          @media screen and (max-width: 576px) {
            flex-basis: 45%;
          }
          @media screen and (max-width: 340px) {
            width: 100%;
          }
        }

        &__volume {
          @media screen and (max-width: 1200px) {
            gap: 0.5rem;
          }
          @media screen and (max-width: 825px) {
            flex-basis: 23%;
          }
          @media screen and (max-width: 576px) {
            flex-basis: 45%;
          }
          @media screen and (max-width: 340px) {
            width: 100%;
          }

          span {
            padding: 0;
          }
        }
      }
    }
  }
}
