.courseSlider {
  width: 100%;
  height: 80%;
}

.swiper {
  margin: 0 auto;
  width: 100%;

  // swiper slide
  &-slide {
    text-align: center;
    font-size: 18px;
    user-select: none;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
}
