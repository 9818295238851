.slide {
  // width: 100%;
  height: 63vh;
  display: flex;
  position: relative;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  font-family: Peyda;
  // margin: 2rem 2rem 0;
  border-radius: 1rem;
  @media screen and (max-width: 768px) {
    height: 60vh;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (max-width: 576px) {
    height: 63vh;
  }
  @media screen and (max-width: 450px) {
    border-radius: 0.5rem;
    height: 63vh;
  }

  &__info {
    padding: 1rem 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    transition: all 0.2s ease;
    @media screen and (max-width: 1024px) {
      padding: 1rem;
    }
    @media screen and (max-width: 768px) {
      padding: 1rem 0.5rem;
    }

    &__hide {
      transform: translateX(100%);
    }

    h1,
    p {
      margin: 0;
      padding: 0;
    }

    h1 {
      font-weight: bold;
      font-size: 4em;
      @media screen and (max-width: 1024px) {
        font-size: 2em;
      }
      @media screen and (max-width: 450px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 1.5em;
      color: #575866;
      @media screen and (max-width: 1024px) {
        font-size: 1em;
      }
      @media screen and (max-width: 450px) {
        font-size: 0.8em;
      }
    }
  }

  &__slider {
    width: 30%;
    position: absolute;
    bottom: 10%;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    padding-right: 3rem;
    font-family: Peyda;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 1024px) {
      width: 50%;
    }
    @media screen and (max-width: 768px) {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      position: unset;
      padding: 0.5rem;
      bottom: 100%;
      right: 0;
    }
    @media screen and (max-width: 576px) {
      display: none;
    }

    &__hide {
      position: absolute;
      bottom: 10%;
      left: -100%;
      transition: all 0.2s ease;
      @media screen and (max-width: 768px) {
        transform: translateX(100%);
        bottom: 100%;
      }
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    &__content {
      text-align: right;

      &--button {
        background-color: #fff;
        padding: 1.5rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: -7%;
        transform: translate(0, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
          rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        transition: all 0.4s;
        @media screen and (max-width: 1024px) {
          padding: 1.3rem;
        }
        @media screen and (max-width: 768px) {
          top: 95%;
          right: 40%;
          padding: 0.8rem;
        }

        &:hover {
          box-shadow: rgba(14, 30, 37, 0.4) 0px 10px 20px 0px,
            rgba(14, 30, 37, 0.5) 0px 10px 32px 0px;
        }
      }

      &--title {
        color: #1d1d35;
      }

      &--img {
        width: 20rem;
        height: 11rem;
        border-radius: 0.3rem;
        @media screen and (max-width: 1024px) {
          margin: 0.5rem 0;
        }
        @media screen and (max-width: 768px) {
          height: 8.5rem;
          margin: 0;
        }
      }

      &--description {
        font-family: peyda-light;
        font-weight: 600;
        color: #575866;
        @media screen and (max-width: 1024px) {
          font-size: 0.9em;
        }
      }

      p,
      h1 {
        margin: 0;
      }
      h3 {
        margin-top: 0;
      }

      &--description,
      &--title,
      h1 {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  &__show {
    display: none;
    @media screen and (max-width: 576px) {
      background-color: #fff;
      width: 9rem;
      height: 2.5rem;
      font-size: 1.2em !important;
      border-radius: 0.5rem;
      transform: translate(0, -50%);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
        rgb(14 30 37 / 32%) 0px 2px 16px 0px;
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 450px) {
      width: 8rem;
      height: 2.3rem;
      font-size: 1em !important;
    }

    & > svg {
      height: 1rem;
      width: 1rem;
    }
  }
}
