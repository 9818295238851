.courseCard {
  width: 100%;
  background-color: #fff;
  border-radius: 1rem;

  &__image {
    img {
      border-radius: 1rem;
      width: 100%;
      height: 12rem;
      object-fit: cover;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        height: 9rem;
      }
    }
  }

  &__box {
    padding: 0 1rem;

    &__tags {
      display: flex;
      justify-content: flex-end;
    }

    &__title {
      text-align: right;
      margin-bottom: 0.8rem;
      word-spacing: 2px;

      span {
        font-family: Peyda-Semibold;
        font-size: clamp(0.7em, 1.2vw, 1em);
      }
    }

    &__description {
      text-align: right;

      p {
        font-family: Peyda-light;
        font-size: clamp(0.6em, 1vw, 0.9em);
      }
    }
  }

  &__footer {
    margin-top: 1rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;

    &__top {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #f0f0f0;

      span {
        color: #666;
        font-size: clamp(0.4em, 1vw, 0.7em);
      }

      &__teacher {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        span {
          &:hover {
            color: #0da3e8;
          }
        }

        &--icon {
          margin-bottom: 0.25rem;
        }
      }

      &__time {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &--icon {
          margin-bottom: 0.25rem;
          color: #666;
        }
      }

      &__rating {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        span {
          padding: 0.25rem 0;
          color: #fbbf24;
        }

        &--icon {
          margin-bottom: 0.25rem;
          color: #fbbf24;
          fill: #fbbf24;
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 1rem 0;

      span {
        font-size: clamp(0.6em, 1vw, 1em);
      }

      &__student {
        display: flex;
        gap: 0.3rem;
      }

      &__price {
        display: flex;
        gap: 0.3rem;

        span {
          font-size: clamp(0.6em, 2vw, 1.1em);
          // color: #81abb9;
          color: #0da3e8;
          font-family: Peyda-Semibold;
        }
      }
    }
  }
}
